import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../spinner/Spinner";
import api from "../../../../services/api";
import PartnerDriver from "./PartnerDrivers";
import axios from "axios";
import { BASE_URL } from "../../../../services/config";

const MyDrivers = ({
  isOpen,
  setIsOpen,
  partnerId, // Make sure partnerId is passed to this component
}) => {
  const [mydrivers, setmydrivers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [workAreas, setWorkAreas] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState("success"); // "success" or "error"
  const [validationErrors, setValidationErrors] = useState({});

  // Initialize form data with partner ID
  const [driverFormData, setDriverFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    workArea: "",
    idNumber: "",
    licensedVehicle: "",
    dateOfBirth: "",
    gender: "",
    partner: partnerId,
    idPhoto: null,
    psvPhoto: null,
    goodConductPhoto: null,
    licensePhoto: null,
  });

  // Fetch drivers
  useEffect(() => {
    const fetchMyDrivers = async () => {
      setLoading(true);
      try {
        const response = await api.get("/api/partner-drivers/");
        setmydrivers(response);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchMyDrivers();
  }, [error]);

  // Fetch vehicle types and work areas
  useEffect(() => {
    const fetchData = async () => {
      try {
        const vehicleResponse = await axios.get(
          `${BASE_URL}api/vehicle-types/`
        );
        setVehicleTypes(vehicleResponse.data);

        const workAreasResponse = await axios.get(`${BASE_URL}api/work-areas/`);
        setWorkAreas(workAreasResponse.data);
      } catch (error) {
        console.error("Error fetching vehicle types or work areas:", error);
      }
    };

    fetchData();
  }, []);

  // Hide popup after 5 seconds
  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showPopup]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDriverFormData({
      ...driverFormData,
      [name]: value,
    });

    // Clear validation error when field is edited
    if (validationErrors[name]) {
      setValidationErrors({
        ...validationErrors,
        [name]: null,
      });
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (validTypes.includes(file.type)) {
        setDriverFormData({ ...driverFormData, [name]: file });

        // Clear validation error when file is selected
        if (validationErrors[name]) {
          setValidationErrors({
            ...validationErrors,
            [name]: null,
          });
        }
      } else {
        alert("Invalid file type. Please upload a PNG, JPG, or JPEG image.");
      }
    }
  };

  const validateForm = () => {
    const errors = {};

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(driverFormData.email)) {
      errors.email = "Please enter a valid email address";
    }

    // Phone number validation - must be in international format like +2547XXXXXXXX
    const phoneRegex = /^\+\d{10,15}$/;
    if (!phoneRegex.test(driverFormData.mobileNumber)) {
      errors.mobileNumber =
        "Please enter a valid phone number in international format (+2547XXXXXXXX)";
    }

    // Date of birth validation - must be 18 years or older
    if (driverFormData.dateOfBirth) {
      const birthDate = new Date(driverFormData.dateOfBirth);
      const today = new Date();
      const age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (
        age < 18 ||
        (age === 18 && monthDiff < 0) ||
        (age === 18 && monthDiff === 0 && today.getDate() < birthDate.getDate())
      ) {
        errors.dateOfBirth = "Driver must be at least 18 years old";
      }
    }

    // Required field validation
    if (!driverFormData.firstName.trim())
      errors.firstName = "First name is required";
    if (!driverFormData.lastName.trim())
      errors.lastName = "Last name is required";
    if (!driverFormData.idNumber.trim())
      errors.idNumber = "ID number is required";
    if (!driverFormData.workArea) errors.workArea = "Work area is required";
    if (!driverFormData.licensedVehicle)
      errors.licensedVehicle = "Vehicle type is required";
    if (!driverFormData.gender) errors.gender = "Gender is required";
    if (!driverFormData.idPhoto) errors.idPhoto = "ID photo is required";
    if (!driverFormData.psvPhoto) errors.psvPhoto = "PSV photo is required";
    if (!driverFormData.goodConductPhoto)
      errors.goodConductPhoto = "Good conduct photo is required";
    if (!driverFormData.licensePhoto)
      errors.licensePhoto = "License photo is required";

    // Verify partner ID exists
    if (!partnerId) {
      errors.partner = "Partner ID is missing";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const showErrorPopup = (message) => {
    console.log("Showing error popup:", message);
    setPopupType("error");
    setPopupMessage(message);
    setShowPopup(true);
  };

  const showSuccessPopup = (message) => {
    console.log("Showing success popup:", message);
    setPopupType("success");
    setPopupMessage(message);
    setShowPopup(true);
  };

  const formatErrorMessage = (errorData) => {
    if (typeof errorData === "string") {
      return errorData;
    }

    // Special case for common errors
    if (errorData.non_field_errors) {
      if (Array.isArray(errorData.non_field_errors)) {
        if (
          errorData.non_field_errors.includes(
            "A user with this email already exists."
          )
        ) {
          return "A user with this email already exists. Please use a different email address.";
        }
        return errorData.non_field_errors.join(", ");
      }
      return errorData.non_field_errors;
    }

    if (typeof errorData === "object") {
      const errorMessages = [];
      for (const [key, value] of Object.entries(errorData)) {
        if (Array.isArray(value)) {
          errorMessages.push(`${key}: ${value.join(", ")}`);
        } else if (typeof value === "string") {
          errorMessages.push(`${key}: ${value}`);
        } else if (typeof value === "object" && value !== null) {
          errorMessages.push(`${key}: ${formatErrorMessage(value)}`);
        }
      }
      return errorMessages.join(" | ");
    }

    return "Unknown error occurred";
  };

  const handleDriverSubmit = async (e) => {
    e.preventDefault();

    // Validate form
    if (!validateForm()) {
      // Form has validation errors
      return;
    }

    setIsSubmitting(true);

    // Clear any previous popup
    setShowPopup(false);

    const submissionData = new FormData();

    // Add all form fields to FormData
    submissionData.append("first_name", driverFormData.firstName);
    submissionData.append("last_name", driverFormData.lastName);
    submissionData.append("email", driverFormData.email);
    submissionData.append("mobile_number", driverFormData.mobileNumber);
    submissionData.append("work_area", driverFormData.workArea);
    submissionData.append("id_number", driverFormData.idNumber);
    submissionData.append("vehicle_type", driverFormData.licensedVehicle);
    submissionData.append("date_of_birth", driverFormData.dateOfBirth);
    submissionData.append("gender", driverFormData.gender);
    submissionData.append("partner", partnerId);

    // Verify partner ID is valid before submitting
    if (!partnerId) {
      showErrorPopup("Partner ID is missing. Please log out and log back in.");
      setIsSubmitting(false);
      return;
    }

    // Add files if they exist
    if (driverFormData.idPhoto) {
      submissionData.append("id_photo", driverFormData.idPhoto);
    }
    if (driverFormData.psvPhoto) {
      submissionData.append("psv_photo", driverFormData.psvPhoto);
    }
    if (driverFormData.goodConductPhoto) {
      submissionData.append(
        "good_conduct_photo",
        driverFormData.goodConductPhoto
      );
    }
    if (driverFormData.licensePhoto) {
      submissionData.append("license_photo", driverFormData.licensePhoto);
    }

    try {
      const response = await axios.post(
        `${BASE_URL}api/drivers/`,
        submissionData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 201) {
        // Success - refresh driver list
        try {
          const updatedDrivers = await api.get("/api/partner-drivers/");
          setmydrivers(updatedDrivers);
        } catch (refreshError) {
          console.error("Error refreshing driver list:", refreshError);
        }

        // Reset form and close dialog
        setDriverFormData({
          firstName: "",
          lastName: "",
          email: "",
          mobileNumber: "",
          workArea: "",
          idNumber: "",
          licensedVehicle: "",
          dateOfBirth: "",
          gender: "",
          partner: partnerId,
          idPhoto: null,
          psvPhoto: null,
          goodConductPhoto: null,
          licensePhoto: null,
        });
        setValidationErrors({});
        setIsOpen(false);

        // Show success popup using fixed position
        setTimeout(() => {
          showSuccessPopup("Driver added successfully!");
        }, 300); // Small delay to ensure modal is closed first
      }
    } catch (error) {
      console.error("Error adding driver:", error);

      let errorMsg = "Failed to add driver. Please try again.";

      if (error.response) {
        console.error("Error status:", error.response.status);
        console.error("Error data:", JSON.stringify(error.response.data));

        if (error.response.data) {
          // Handle specific cases
          if (
            error.response.data.non_field_errors &&
            Array.isArray(error.response.data.non_field_errors) &&
            error.response.data.non_field_errors.includes(
              "A user with this email already exists."
            )
          ) {
            // Set validation error for email field
            setValidationErrors({
              ...validationErrors,
              email:
                "This email is already in use. Please use a different email address.",
            });
            errorMsg =
              "A user with this email already exists. Please use a different email address.";
          } else {
            errorMsg = formatErrorMessage(error.response.data);
          }
        }
      }

      // Show error popup
      showErrorPopup(errorMsg);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="relative bg-white bg-opacity-60 rounded-xl mb-8 mt-2 shadow-lg py-4 h-[80vh] md:h-[60vh] w-[90%] flex flex-col items-start justify-start overflow-y-auto custom-scrollbar">
      {/* Fixed position popup notification - global scope */}
      {showPopup && (
        <div
          className={`fixed top-10 left-1/2 transform -translate-x-1/2 ${
            popupType === "success" ? "bg-green-500" : "bg-red-500"
          } text-white p-4 rounded shadow-lg z-[9999] font-serif text-sm md:text-base max-w-xs sm:max-w-sm md:max-w-md`}
          style={{ boxShadow: "0 0 20px rgba(0, 0, 0, 0.3)" }}
        >
          {popupMessage}
        </div>
      )}

      <button
        onClick={() => setIsOpen(true)}
        className="absolute text-white bg-orange-700 hover:bg-orange-800 rounded-lg py-1 px-1.5 right-5 top-5 text-xs sm:text-sm md:text-base"
      >
        Add New Driver
      </button>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-[990]"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-2 sm:p-4">
          <Dialog.Panel className="mx-auto w-full max-w-4xl rounded-lg bg-white max-h-[90vh] overflow-y-auto">
            <div className="p-3 sm:p-5 md:p-8">
              <div className="flex items-center justify-between mb-4 sm:mb-8">
                <Dialog.Title className="text-lg sm:text-xl md:text-2xl font-bold text-gray-800">
                  Add New Driver
                </Dialog.Title>
                <button
                  onClick={() => setIsOpen(false)}
                  className="text-gray-500 hover:text-gray-700 text-2xl sm:text-3xl md:text-4xl font-bold"
                >
                  ×
                </button>
              </div>

              <form
                onSubmit={handleDriverSubmit}
                className="space-y-4 sm:space-y-6"
              >
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 sm:gap-4 md:gap-6">
                  <div>
                    <label className="block text-xs sm:text-sm font-medium text-black mb-1">
                      FIRST NAME <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      className={`w-full px-2 sm:px-3 py-1 sm:py-2 border ${
                        validationErrors.firstName
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm`}
                      value={driverFormData.firstName}
                      onChange={handleInputChange}
                      required
                    />
                    {validationErrors.firstName && (
                      <p className="text-red-500 text-xs mt-1">
                        {validationErrors.firstName}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-xs sm:text-sm font-medium text-black mb-1">
                      LAST NAME <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      className={`w-full px-2 sm:px-3 py-1 sm:py-2 border ${
                        validationErrors.lastName
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm`}
                      value={driverFormData.lastName}
                      onChange={handleInputChange}
                      required
                    />
                    {validationErrors.lastName && (
                      <p className="text-red-500 text-xs mt-1">
                        {validationErrors.lastName}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-xs sm:text-sm font-medium text-black mb-1">
                      EMAIL <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      className={`w-full px-2 sm:px-3 py-1 sm:py-2 border ${
                        validationErrors.email
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm`}
                      value={driverFormData.email}
                      onChange={handleInputChange}
                      required
                    />
                    {validationErrors.email && (
                      <p className="text-red-500 text-xs mt-1">
                        {validationErrors.email}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-xs sm:text-sm font-medium text-black mb-1">
                      MOBILE NUMBER <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="tel"
                      name="mobileNumber"
                      className={`w-full px-2 sm:px-3 py-1 sm:py-2 border ${
                        validationErrors.mobileNumber
                          ? "border-red-500"
                          : "border-gray-300"
                      } text-gray-600 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm`}
                      value={driverFormData.mobileNumber}
                      placeholder="+2547XXXXXXXX"
                      onChange={handleInputChange}
                      required
                    />
                    {validationErrors.mobileNumber && (
                      <p className="text-red-500 text-xs mt-1">
                        {validationErrors.mobileNumber}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-xs sm:text-sm font-medium text-black mb-1">
                      WORK AREA <span className="text-red-500">*</span>
                    </label>
                    <select
                      name="workArea"
                      className={`w-full px-2 sm:px-3 py-1 sm:py-2 border ${
                        validationErrors.workArea
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm`}
                      value={driverFormData.workArea}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Work Area</option>
                      {workAreas.map((area) => (
                        <option key={area.id} value={area.id}>
                          {area.name}
                        </option>
                      ))}
                    </select>
                    {validationErrors.workArea && (
                      <p className="text-red-500 text-xs mt-1">
                        {validationErrors.workArea}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-xs sm:text-sm font-medium text-black mb-1">
                      ID NUMBER <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="idNumber"
                      className={`w-full px-2 sm:px-3 py-1 sm:py-2 border ${
                        validationErrors.idNumber
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm`}
                      value={driverFormData.idNumber}
                      onChange={handleInputChange}
                      required
                    />
                    {validationErrors.idNumber && (
                      <p className="text-red-500 text-xs mt-1">
                        {validationErrors.idNumber}
                      </p>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 sm:gap-4 md:gap-6">
                  <div>
                    <label className="block text-xs sm:text-sm font-medium text-black mb-1">
                      LICENSED VEHICLE <span className="text-red-500">*</span>
                    </label>
                    <select
                      name="licensedVehicle"
                      className={`w-full px-2 sm:px-3 py-1 sm:py-2 border ${
                        validationErrors.licensedVehicle
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm`}
                      value={driverFormData.licensedVehicle}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Vehicle Type</option>
                      {vehicleTypes.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                    </select>
                    {validationErrors.licensedVehicle && (
                      <p className="text-red-500 text-xs mt-1">
                        {validationErrors.licensedVehicle}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-xs sm:text-sm font-medium text-black mb-1">
                      DATE OF BIRTH <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="date"
                      name="dateOfBirth"
                      placeholder="mm/dd/yyyy"
                      className={`w-full px-2 sm:px-3 py-1 sm:py-2 border ${
                        validationErrors.dateOfBirth
                          ? "border-red-500"
                          : "border-gray-300"
                      } text-gray-600 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm`}
                      value={driverFormData.dateOfBirth}
                      onChange={handleInputChange}
                      required
                    />
                    {validationErrors.dateOfBirth && (
                      <p className="text-red-500 text-xs mt-1">
                        {validationErrors.dateOfBirth}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-xs sm:text-sm font-medium text-black mb-1">
                      GENDER <span className="text-red-500">*</span>
                    </label>
                    <select
                      name="gender"
                      className={`w-full px-2 sm:px-3 py-1 sm:py-2 border ${
                        validationErrors.gender
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm`}
                      value={driverFormData.gender}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </select>
                    {validationErrors.gender && (
                      <p className="text-red-500 text-xs mt-1">
                        {validationErrors.gender}
                      </p>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3 sm:gap-4 md:gap-6">
                  <div>
                    <label className="block text-xs sm:text-sm font-medium text-black mb-1">
                      ID PHOTO <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <button
                        type="button"
                        className={`w-full px-2 sm:px-3 py-1 sm:py-2 text-gray-600 text-left border ${
                          validationErrors.idPhoto
                            ? "border-red-500"
                            : "border-gray-300"
                        } rounded-md bg-white hover:bg-gray-50 text-sm`}
                      >
                        Choose File
                      </button>
                      <input
                        type="file"
                        name="idPhoto"
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                        onChange={handleFileChange}
                        required
                      />
                    </div>
                    <p className="text-xs text-gray-500 mt-1 truncate">
                      {driverFormData.idPhoto
                        ? driverFormData.idPhoto.name
                        : "No file chosen"}
                    </p>
                    {validationErrors.idPhoto && (
                      <p className="text-red-500 text-xs mt-1">
                        {validationErrors.idPhoto}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-xs sm:text-sm font-medium text-black mb-1">
                      PSV PHOTO <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <button
                        type="button"
                        className={`w-full px-2 sm:px-3 py-1 sm:py-2 text-gray-600 text-left border ${
                          validationErrors.psvPhoto
                            ? "border-red-500"
                            : "border-gray-300"
                        } rounded-md bg-white hover:bg-gray-50 text-sm`}
                      >
                        Choose File
                      </button>
                      <input
                        type="file"
                        name="psvPhoto"
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                        onChange={handleFileChange}
                        required
                      />
                    </div>
                    <p className="text-xs text-gray-500 mt-1 truncate">
                      {driverFormData.psvPhoto
                        ? driverFormData.psvPhoto.name
                        : "No file chosen"}
                    </p>
                    {validationErrors.psvPhoto && (
                      <p className="text-red-500 text-xs mt-1">
                        {validationErrors.psvPhoto}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-xs sm:text-sm font-medium text-black mb-1">
                      GOOD CONDUCT <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <button
                        type="button"
                        className={`w-full px-2 sm:px-3 py-1 sm:py-2 text-gray-600 text-left border ${
                          validationErrors.goodConductPhoto
                            ? "border-red-500"
                            : "border-gray-300"
                        } rounded-md bg-white hover:bg-gray-50 text-sm`}
                      >
                        Choose File
                      </button>
                      <input
                        type="file"
                        name="goodConductPhoto"
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                        onChange={handleFileChange}
                        required
                      />
                    </div>
                    <p className="text-xs text-gray-500 mt-1 truncate">
                      {driverFormData.goodConductPhoto
                        ? driverFormData.goodConductPhoto.name
                        : "No file chosen"}
                    </p>
                    {validationErrors.goodConductPhoto && (
                      <p className="text-red-500 text-xs mt-1">
                        {validationErrors.goodConductPhoto}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-xs sm:text-sm font-medium text-black mb-1">
                      LICENSE <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <button
                        type="button"
                        className={`w-full px-2 sm:px-3 py-1 sm:py-2 text-gray-600 text-left border ${
                          validationErrors.licensePhoto
                            ? "border-red-500"
                            : "border-gray-300"
                        } rounded-md bg-white hover:bg-gray-50 text-sm`}
                      >
                        Choose File
                      </button>
                      <input
                        type="file"
                        name="licensePhoto"
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                        onChange={handleFileChange}
                        required
                      />
                    </div>
                    <p className="text-xs text-gray-500 mt-1 truncate">
                      {driverFormData.licensePhoto
                        ? driverFormData.licensePhoto.name
                        : "No file chosen"}
                    </p>
                    {validationErrors.licensePhoto && (
                      <p className="text-red-500 text-xs mt-1">
                        {validationErrors.licensePhoto}
                      </p>
                    )}
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full bg-orange-600 text-white py-2 sm:py-3 px-4 rounded-md hover:bg-orange-700 transition-colors text-sm sm:text-base"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Adding Driver..." : "Add New Driver"}
                </button>
              </form>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      <div className="flex flex-col gap-0.5 mt-12 w-full overflow-y-auto custom-scrollbar">
        {mydrivers && mydrivers.length > 0 ? (
          mydrivers.map((driver) => (
            <PartnerDriver
              key={driver.id}
              driverInfo={driver}
              className="w-full"
            />
          ))
        ) : (
          <div className="w-full flex items-center justify-center h-[80%]">
            {loading ? (
              <Spinner />
            ) : (
              <p className="text-black text-sm sm:text-lg xl:text-2xl font-serif font-bold text-center px-4">
                You currently have no drivers added.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyDrivers;
