import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NavBarThree from "../navbar/NavBarThree";
import NavBarTwo from "../navbar/NavBarTwo";
import Footer from "../footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import api from "../../services/api";
import Spinner from "../spinner/Spinner";
import useAuthToken from '../Auth/useAuthToken';

const JobsList = () => {
  const hasToken = useAuthToken();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [mobileItemsPerPage, setMobileItemsPerPage] = useState(6);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [jobData, setJobData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [workAreas, setWorkAreas] = useState([]);
  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");

  // Fetch job data from API
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const jobData = await api.get("api/jobs/");
        const vehicleMakesData = await api.get("api/vehicle-makes/");
        const vehicleModelsData = await api.get("api/vehicle-models/");
        const workAreasData = await api.get("api/work-areas/");

        setVehicleMakes(vehicleMakesData);
        setVehicleModels(vehicleModelsData);
        setWorkAreas(workAreasData);

        // Map job data to include names instead of IDs
        const mappedData = jobData.map((job) => {
          const make =
            vehicleMakesData.find((make) => make.id === job.vehicle_make)
              ?.name || "Unknown";
          const model =
            vehicleModelsData.find((model) => model.id === job.vehicle_model)
              ?.name || "Unknown";
          const location =
            workAreasData.find((area) => area.id === job.preferred_work_area)
              ?.name || "Unknown";

          return {
            id: job.id,
            location,
            type: model,
            make,
            photo: job.vehicle_photo,
          };
        });

        setJobData(mappedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching job data:", error);
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < jobData.length) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleLoadMore = () => {
    setMobileItemsPerPage((prev) => prev + 6);
  };

  const handleFirstPage = () => setCurrentPage(1);
  const handleLastPage = () =>
    setCurrentPage(Math.ceil(jobData.length / itemsPerPage));

  const startIndex = (currentPage - 1) * itemsPerPage;

  const handleMakeChange = (e) => {
    setSelectedMake(e.target.value);
  };

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
  };

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  // Filtering the jobData based on selectedMake, selectedModel, and selectedLocation
  const filteredJobData = jobData.filter((job) => {
    const matchMake = selectedMake
      ? job.make ===
      vehicleMakes.find((make) => make.id === parseInt(selectedMake))?.name
      : true;
    const matchModel = selectedModel
      ? job.type ===
      vehicleModels.find((model) => model.id === parseInt(selectedModel))
        ?.name
      : true;
    const matchLocation = selectedLocation
      ? job.location === selectedLocation
      : true;

    return matchMake && matchModel && matchLocation;
  });

  const totalPages = Math.ceil(
    filteredJobData.length / (isMobile ? mobileItemsPerPage : itemsPerPage)
  );

  const currentData = isMobile
    ? filteredJobData.slice(0, mobileItemsPerPage)
    : filteredJobData.slice(startIndex, startIndex + itemsPerPage);

  // const totalPages = Math.ceil(filteredJobData.length / itemsPerPage);

  // if (loading) {
  //   return <div>< Spinner /></div>;
  // }


  return (
    <div className="flex flex-col min-h-screen bg-cover" style={{ backgroundImage: "url(/assets/background3.svg)" }}>
      {!hasToken ? <NavBarTwo /> : <NavBarThree />}

      <div className="flex-grow relative flex justify-center ">

        <div className="bg-white bg-opacity-60 rounded-xl mb-0 mt-6 shadow-lg w-full max-w-full h-full overflow-y-auto mx-4 md:mx-8">

          {loading ? (
            <div className="flex justify-center items-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div className="bg-gray-100 flex p-2 rounded-xl w-full items-center space-x-2">
                <h1 className="font-serif py-2 text-xs md:text-sm xl:text-lg flex-shrink-0 w-[15%]">
                  Find A Job
                </h1>
                <div className="flex space-x-2 w-[65%]">
                  <div className="flex flex-col flex-1">
                    <select
                      id="vehicleMake"
                      name="vehicleMake"
                      className="p-1.5 text-xs xl:text-sm font-serif border bg-gray-300 border-gray-300 rounded-lg w-full"
                      required
                      onChange={handleMakeChange}
                      value={selectedMake}
                    >
                      {!selectedMake && (
                        <option value="" disabled hidden>
                          Vehicle Make
                        </option>
                      )}
                      {vehicleMakes.map((make) => (
                        <option key={make.id} value={make.id}>
                          {make.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="flex flex-col flex-1">
                    <select
                      id="vehicleModel"
                      name="vehicleModel"
                      className="p-1.5 text-xs xl:text-sm font-serif border bg-gray-300 border-gray-300 rounded-lg w-full"
                      required
                      onChange={handleModelChange}
                      value={selectedModel}
                      disabled={!selectedMake} // Disable if no make is selected
                    >
                      {!selectedModel && (
                        <option value="" disabled hidden>
                          Vehicle Model
                        </option>
                      )}
                      {vehicleModels
                        .filter((model) => model.make.id === parseInt(selectedMake))
                        .map((model) => (
                          <option key={model.id} value={model.id}>
                            {model.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="flex flex-col flex-1">
                    <select
                      id="workArea"
                      name="workArea"
                      className="p-1.5 text-xs xl:text-sm font-serif border bg-gray-300 border-gray-300 rounded-lg w-full"
                      required
                      onChange={handleLocationChange}
                      value={selectedLocation}
                    >
                      {!selectedLocation && (
                        <option value="" disabled hidden>
                          Location
                        </option>
                      )}
                      {workAreas.map((area) => (
                        <option key={area.id} value={area.name}>
                          {area.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex-shrink-0 w-[20%]">
                  <button
                    type="button"
                    className="py-2 px-4 bg-orange-700 rounded-lg text-xs xl:text-lg font-serif text-white font-semibold w-full"
                    onClick={() => {
                      setSelectedMake("");
                      setSelectedModel("");
                      setSelectedLocation("");
                    }}
                  >
                    Clear Search
                  </button>
                </div>
              </div>
              <div className="w-full px-4 pt-4 pb-8">
                {filteredJobData.length > 0 && (
                  <h1 className="font-serif text-xl text-black font-bold flex items-start px-4 py-2">
                    Jobs For You
                  </h1>
                )}
                <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
                  {filteredJobData.length === 0 ? (
                    <div className="col-span-full text-center xl:py-32 py-20 text-2xl xl:text-4xl text-gray-500 font-bold font-serif">
                      No jobs Available
                    </div>
                  ) : (
                    currentData.map((job) => (
                      <Link
                        to={`/jobs/${job.id}`}
                        key={job.id}
                        className="bg-gray-300 w-full py-3 md:py-6 px-1 md:px-4 flex flex-col items-start rounded-xl gap-1"
                      >
                        <p className="text-xs xl:text-lg font-serif mr-2 ">
                          <strong>JOB ID:</strong>  {job.id}
                        </p>
                        <p className="text-xs xl:text-lg font-serif mr-2">
                          <strong>Location:</strong> {job.location}
                        </p>
                        <p className="text-xs xl:text-lg font-serif mr-2">
                          <strong>Make:</strong>   {job.make}
                        </p>
                        <p className="text-xs xl:text-lg font-serif mr-2">
                          <strong>Model:</strong> {job.type}
                        </p>
                        <div className="flex w-full">
                          <p className="text-xs xl:text-lg font-serif mr-4"> <strong>Photo:</strong></p>
                          <div className="p-2 w-[40%] bg-orange-600 rounded-lg">
                            <img
                              src={job.photo}
                              alt="Car"
                              className="w-full h-auto"
                            />
                          </div>
                        </div>
                      </Link>
                    ))
                  )}
                </div>
                <div className="mt-8">
                  {isMobile ? (
                    mobileItemsPerPage < jobData.length && (
                      <button
                        onClick={handleLoadMore}
                        className="py-2 px-4 bg-gray-700 rounded-lg text-xs xl:text-lg font-serif text-white"
                      >
                        Load More ...
                      </button>
                    )
                  ) : (
                    <div className="flex justify-center gap-2">
                      <button
                        onClick={handleFirstPage}
                        className="py-2 px-4 bg-gray-700 rounded-lg text-xs xl:text-lg font-serif text-white"
                      >
                        {1}
                      </button>
                      <button
                        onClick={handlePreviousPage}
                        className="py-2 px-4 bg-gray-700 rounded-lg text-xs xl:text-lg font-serif text-white"
                      >
                        <FontAwesomeIcon icon={faAngleLeft} /> Back
                      </button>
                      <span className="py-2 px-4 text-xs xl:text-lg font-serif">
                        {currentPage}
                      </span>
                      <button
                        onClick={handleNextPage}
                        className="py-2 px-4 bg-gray-700 rounded-lg text-xs xl:text-lg font-serif text-white"
                      >
                        Next <FontAwesomeIcon icon={faAngleRight} />
                      </button>
                      <button
                        onClick={handleLastPage}
                        className="py-2 px-4 bg-gray-700 rounded-lg text-xs xl:text-lg font-serif text-white"
                      >
                        {totalPages}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default JobsList;
