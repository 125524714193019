import React, { useEffect, useState } from "react";
import api from "../../services/api";
import NavBarThree from "../navbar/NavBarThree";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../footer/Footer";
import Spinner from "../spinner/Spinner";
import { Link } from 'react-router-dom';
// import { FaGift } from "react-icons/fa";
// import { MdSmartphone } from "react-icons/md";

const API_JOBS = "/api/jobs/";
const API_VEHICLE_MAKES = "/api/vehicle-makes/";
const API_VEHICLE_MODELS = "/api/vehicle-models/";
const API_WORK_AREAS = "/api/work-areas/";
const API_USER_ME = "/api/users/me/";
const API_APPLICATIONS = "/api/applications/";

function JobDetails() {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [workAreas, setWorkAreas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [driverId, setDriverId] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChecked, setIsChecked] = useState(false); 
  

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const jobData = await api.get(`${API_JOBS}${jobId}/`);
        setJob(jobData);
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };

    const fetchVehicleMakes = async () => {
      try {
        const vehicleMakesData = await api.get(API_VEHICLE_MAKES);
        setVehicleMakes(vehicleMakesData);
      } catch (error) {
        console.error("Error fetching vehicle makes:", error);
      }
    };

    const fetchVehicleModels = async () => {
      try {
        const vehicleModelsData = await api.get(API_VEHICLE_MODELS);
        setVehicleModels(vehicleModelsData);
      } catch (error) {
        console.error("Error fetching vehicle models:", error);
      }
    };

    const fetchWorkAreas = async () => {
      try {
        const workAreasData = await api.get(API_WORK_AREAS);
        setWorkAreas(workAreasData);
      } catch (error) {
        console.error("Error fetching work areas:", error);
      }
    };

    const fetchDriverDetails = async () => {
      try {
        const userData = await api.get(API_USER_ME);
        if (userData.driver_details) {
          setDriverId(userData.driver_details.id);
        }
      } catch (error) {
        console.error("Error fetching driver details:", error);
      }
    };

    Promise.all([
      fetchJobDetails(),
      fetchVehicleMakes(),
      fetchVehicleModels(),
      fetchWorkAreas(),
      fetchDriverDetails(),
    ])
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Error during data fetching:", error);
        setLoading(false);
      });
  }, [jobId]);


  const handleClick = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");

    if (driverId && job) {
      try {
        const applicationData = {
          job: job.id,
          driver: driverId,
        };


        await api.post(API_APPLICATIONS, applicationData);

        navigate("/jobsubmission", { state: { jobId: job.id } });
      } catch (error) {
        if (error.response) {
          console.error("Error response from backend:", error.response);

          if (error.response.status === 400) {
            if (error.response.data && error.response.data.non_field_errors) {
              setErrorMessage(error.response.data.non_field_errors.join(' '));
            } else if (error.response.data && typeof error.response.data === 'object') {
              const errorMessages = Object.values(error.response.data).flat().join(' ');
              setErrorMessage(errorMessages);
            } else {
              setErrorMessage("An unexpected error occurred. Please try again.");
            }
          } else {
            setErrorMessage("An error occurred. Please try again later.");
          }
        } else {
          console.error("Error with no response:", error);
          setErrorMessage("Failed to submit application. Please check your internet connection.");
        }
      } finally {
        setIsSubmitting(false);
      }
    } else {
      console.error("Driver ID or Job data is missing");
      setErrorMessage("Driver ID or Job data is missing. Please fill out all required fields.");
      setIsSubmitting(false);
    }
  };


  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const jobVehicleMake = vehicleMakes.find(
    (make) => make.id === job?.vehicle_make
  );
  const jobVehicleModel = vehicleModels.find(
    (model) => model.id === job?.vehicle_model
  );
  const jobWorkArea = workAreas.find(
    (area) => area.id === job?.preferred_work_area
  );

  return (
    <div
      className="flex flex-col min-h-screen bg-cover"
      style={{ backgroundImage: "url(/assets/background3.svg)" }}
    >
      <NavBarThree />

      <div className="flex-grow relative flex justify-center">
        <div className="bg-white bg-opacity-60 rounded-xl mb-8 mt-6 shadow-lg w-full max-w-full h-auto overflow-y-auto mx-4 md:mx-8">
          {job && (
            <div className="md:flex justify-start items-start w-full">
              <div className="md:w-2/5 px-4 pt-4 pb-8">
                <h1 className="font-serif text-xl text-black xl:text-2xl text-left font-bold px-4 py-2">
                  Jobs For You
                </h1>
                <div className="grid grid-cols-1 gap-4 ">
                  <div
                    key={job.id}
                    className="bg-gray-300 w-full py-4 px-1 md:px-6 flex flex-col items-start rounded-xl gap-1"
                  >
                    <p className="text-xs xl:text-lg font-serif mr-2">
                      <span className="font-bold">Job ID:</span> {job.id}
                    </p>

                    <p className="text-xs xl:text-lg font-serif mr-2">
                      <strong>Location:</strong>{" "}
                      {jobWorkArea ? jobWorkArea.name : "Unknown"}
                    </p>
                    <p className="text-xs xl:text-lg font-serif mr-2">
                      <strong>Make:</strong>{" "}
                      {jobVehicleMake ? jobVehicleMake.name : "Unknown"}
                    </p>
                    <p className="text-xs xl:text-lg font-serif mr-2">
                      <strong>Model:</strong>{" "}
                      {jobVehicleModel ? jobVehicleModel.name : "Unknown"}
                    </p>
                    <p className="text-xs xl:text-lg font-serif mr-2">
                      <strong>Work Days:</strong> {job.work_days}
                    </p>
                    <p className="text-xs xl:text-lg font-serif mr-2">
                      <strong>Minimum Age:</strong> {job.min_age}
                    </p>
                    <p className="text-xs xl:text-lg font-serif mr-2">
                      <strong>Maximum Age:</strong> {job.max_age}
                    </p>
                    <div className="text-xs xl:text-lg font-serif md:mr-2 text-left">
                      <strong>Requirements:</strong>
                      <p
                        style={{
                          marginTop: "5px",
                          whiteSpace: "pre-wrap",
                          display: "block",
                        }}
                      >
                        {isExpanded
                          ? job.requirements
                          : `${job.requirements.slice(0, 90)}`}
                        {job.requirements.length > 90 && (
                          <>
                            {!isExpanded && "... "}
                            <button
                              onClick={toggleReadMore}
                              className="text-orange-600 underline text-xs xl:text-lg"
                              style={{ display: "inline", cursor: "pointer" }}
                            >
                              {isExpanded ? "Less" : "More"}
                            </button>
                          </>
                        )}
                      </p>
                    </div>
                    <div className="flex flex-col w-full items-start">
                      <p className="text-xs xl:text-lg font-serif mr-0">
                        <strong>Photo:</strong>
                      </p>
                      <div className="p-4 md:w-1/2 w-[70%] bg-gray-400 rounded-lg">
                        <img
                          src={job.vehicle_photo}
                          alt="Car"
                          className="w-full h-auto"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="md:w-3/5 px-4 pt-4 pb-8">
                <h1 className="font-serif xl:text-2xl text-xl text-black text-left font-bold px-4 py-2">
                  Apply here
                </h1>
                {errorMessage && (
                  <div className="bg-red-500  text-xs md:text-sm font-serif text-white w-[80%] md:w-[50%] py-2 mx-4">
                    {errorMessage}
                  </div>
                )}
                <div className="grid p-2 grid-cols-1 gap-4">
                  <div className="bg-gray-300 w-full py-4 px-1 md:px-6 flex flex-col items-start rounded-xl gap-1">
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id="salaryNegotiable"
                        name="salaryNegotiable"
                        className="h-4 w-4 text-orange-700 border-gray-300 rounded focus:ring-orange-600"
                        checked={isChecked} 
                        onChange={handleCheckboxChange} 
                        required
                      />
                      <label htmlFor="salaryNegotiable" className="font-serif">
                        <span className="text-black-600 mb-1 text-black text-xs xl:text-xl font-serif">
                          Fetch Uploaded Profile Information
                        </span>
                      </label>
                    </div>
                    <p className="text-orange-600 xl:text-lg text-xs font-serif py-2 underline">
                      <Link
                        to="/DriverDashboard"
                        state={{ activeSection: "profile" }}
                      >
                        Click to update profile
                      </Link>
                    </p>

                    <div className="py-1 w-full">
                      {/* <h1 className="font-serif font-bold xl:text-2xl text-lg text-left">
                        Make Payment
                      </h1>
                      <p className="font-serif xl:text-lg text-xs text-left py-1">
                        You are applying for
                        <span className="font-semibold">Job ID: {job.id}</span>
                        from Dere.com on June 30th, 2024. Once the application
                        is complete, we will send your details to the partner
                        provider as inputted on your profile.
                      </p>
                      <p className="font-serif xl:text-lg text-xs text-left py-1">
                        The price for our service is
                        <span className="font-semibold"> KSH 100</span>. You
                        will be required to pay this amount for us to process
                        your application.
                      </p>
                      <h2 className="font-serif font-bold xl:text-lg text-sm text-left">
                        PAY KES 100.00
                      </h2>
                      <div className="text-xs py-1 font-serif ml-4">
                        <p className="text-left xl:text-sm ">
                          1. Provide your MPESA mobile number below
                        </p>
                        <p className="text-left xl:text-sm">
                          2. Click Proceed and a prompt will appear on your
                          phone requesting you to confirm the transaction by
                          providing your MPESA PIN.
                        </p>
                        <p className="text-left xl:text-sm">
                          3. Once completed, you will receive the confirmation
                          SMS for this transaction.
                        </p>
                      </div> */}

                      <div className="flex flex-col space-y-3 mt-2">
                        {/* <div className="flex">
                          <div className="md:w-[75%] w-[90%] py-2 bg-gray-400 rounded-lg">
                            <div className="flex items-start">
                              <div className="">
                                <MdSmartphone className="text-black text-xl mt-1" />{" "}
                              </div>
                              <label
                                className="md:ml-7 block text-gray-700 xl:text-lg text-xs md:text-sm mt-1 font-serif"
                                htmlFor="mobileNumber"
                              >
                                +254
                              </label>
                              <input
                                className="mr-6 appearance-none text-xs xl:text-lg md:ml-6 block w-3/4 placeholder-black bg-gray-500 text-gray-700 font-serif border rounded-lg py-1 xl:py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="mobileNumber"
                                type="text"
                                placeholder="e.g 7xx xxx xxx"
                              />
                            </div>
                          </div>
                        </div> */}

                        {/* <div className="flex justify-start items-center mr-96 whitespace-nowrap">
                          <div className="text-xs">Have a voucher code?</div>
                        </div> */}
                        <div className="flex items-center">
                          {/* <div className="md:w-[45%] w-[65%] py-2 bg-gray-400 rounded-lg flex items-center">
                            <div className="pl-2">
                              <FaGift className="text-black text-xl" />{" "}
                            </div>
                            <div className="flex flex-col items-start w-full pl-2">
                              <input
                                className="appearance-none text-xs xl:text-lg block w-full placeholder-black bg-gray-500 text-gray-700 font-serif border rounded-lg py-1 xl:py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="voucherCode"
                                type="text"
                                placeholder="Enter voucher code"
                              />
                            </div>
                          </div> */}
                          <button
                            className="text-white font-serif  text-sm xl:text-xl rounded-xl bg-orange-700 mt-1 py-1.5 px-4 md:px-6 ml-auto"
                            onClick={handleClick}
                            disabled={!isChecked || isSubmitting}
                          >
                            {isSubmitting ? "Submitting..." : "Proceed"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default JobDetails;


