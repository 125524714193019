import React, { useState, useEffect } from "react";
import api from "../../../../services/api";
import axios from "axios";
import { BASE_URL } from "../../../../services/config";

const ProfileSection = ({ profile, getWorkAreaName }) => {
  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refresh_token");
  const usetoken = token || refreshToken;
  const [showPopup, setShowPopup] = useState(false);

  //Declare state inside the component
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState(""); // New confirm password state
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const [passwordSuccess, setPasswordSuccess] = useState(null);

  // Password visibility states
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handlePasswordUpdate = async () => {
    setPasswordLoading(true);
    setPasswordError(null);
    setPasswordSuccess(null);

    let errors = [];

    // Frontend Validation
    if (newPassword.length < 8) {
      errors.push("New password must be at least 8 characters long.");
    }

    if (newPassword !== confirmNewPassword) {
      errors.push("Confirm new password field should match new password field.");
    }

    if (errors.length > 0) {
      setPasswordError(errors.join("\n"));
      setPasswordLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No authentication token found. Please log in again.");
      }

      const response = await axios.post(
        `${BASE_URL}api/update-password`,
        {
          current_password: oldPassword,
          new_password: newPassword,
          confirm_password: confirmNewPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setPasswordSuccess("Password updated successfully!");
        setTimeout(() => {
          setPasswordSuccess("");
        }, 2000);

        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");

        setTimeout(() => {
          setShowPasswordDialog(false); // Close dialog after success
        }, 2000);
      }
    } catch (err) {
      let errorMessage = "Failed to update password.";

      if (err.response) {
        // Handle Django validation errors
        if (err.response.data.detail) {
          if (Array.isArray(err.response.data.detail)) {
            errorMessage = err.response.data.detail.join("\n"); // Join multiple errors
          } else {
            errorMessage = err.response.data.detail;
          }
        }
      } else if (err.message) {
        errorMessage = err.message; // Handle general errors
      }

      setPasswordError(errorMessage);
    } finally {
      setPasswordLoading(false);
    }
  };


  if (!usetoken) {
    throw new Error("No token found. Please log in again.");
  }

  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    first_name: profile.first_name || "",
    last_name: profile.last_name || "",
    partner_details: {
      mobile_number: profile.partner_details?.mobile_number || "",
      id_number: profile.partner_details?.id_number || "",
      company_name: profile.partner_details?.company_name || "",
      company_number: profile.partner_details?.company_number || "",
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [showPopup]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes("partner_details.")) {
      const field = name.split(".")[1];
      setFormData((prev) => ({
        ...prev,
        partner_details: {
          ...prev.partner_details,
          [field]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const userData = await api.get("/api/users/me/");

      const partnerId = userData.partner_details.id;

      const updateData = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        id_number: formData.partner_details.id_number,
        mobile_number: formData.partner_details.mobile_number,
        company_name: formData.partner_details.company_name,
        company_number: formData.partner_details.company_number,
      };

      const response = await axios.put(
        `${BASE_URL}api/partners/${partnerId}/`,
        updateData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: `Bearer ${usetoken}`,
          },
        }
      );

      if (response.status === 200) {
        setShowPopup(true);
        setIsEditing(false);
        setTimeout(() => {
          window.location.reload();
        }, 6000);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      setShowPopup(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-white bg-opacity-50 md:p-2 p-2 flex flex-col rounded-xl w-[90%]">
      {showPopup && (
        <div className="fixed top-26 font-serif left-1/2 transform -translate-x-1/2 bg-green-500 text-white p-4 rounded shadow-md z-50">
          Profile updated successfully
        </div>
      )}
      <div className="space-y-6 md:px-10 md:py-6">
        {/* Rest of your existing JSX code remains the same */}
        <div className="grid grid-cols-2 gap-2 md:gap-8">
          <div className="flex flex-col">
            <label className="mb-1 text-black uppercase text-left xl:text-lg text-xs font-serif">
              Name
            </label>
            {isEditing ? (
              <div className="flex gap-2">
                <input
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                  className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-white rounded-lg w-1/2"
                />
                <input
                  type="text"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                  className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-white rounded-lg w-1/2"
                />
              </div>
            ) : (
              <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                {profile.first_name} {profile.last_name}
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <label className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif">
              Email
            </label>
            <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
              {profile.email}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 md:gap-8">
          <div className="flex flex-col">
            <label className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif">
              ID NUMBER
            </label>
            {isEditing ? (
              <input
                type="text"
                name="partner_details.id_number"
                value={formData.partner_details.id_number}
                onChange={handleInputChange}
                className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-white rounded-lg"
              />
            ) : (
              <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                {profile.partner_details?.id_number}
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <label className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif">
              MOBILE NUMBER
            </label>
            {isEditing ? (
              <input
                type="text"
                name="partner_details.mobile_number"
                value={formData.partner_details.mobile_number}
                onChange={handleInputChange}
                className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-white rounded-lg"
              />
            ) : (
              <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                {profile.partner_details?.mobile_number
                  ? profile.partner_details?.mobile_number
                  : "no mobile number"}
              </div>
            )}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 md:gap-8">
          <div className="flex flex-col">
            <label className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif">
              COMPANY NAME
            </label>
            {isEditing ? (
              <input
                type="text"
                name="partner_details.company_name"
                value={formData.partner_details.company_name}
                onChange={handleInputChange}
                className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-white rounded-lg"
              />
            ) : (
              <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                {profile.partner_details?.company_name}
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <label className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif">
              COMPANY NUMBER
            </label>
            {isEditing ? (
              <input
                type="text"
                name="partner_details.company_number"
                value={formData.partner_details.company_number}
                onChange={handleInputChange}
                className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-white rounded-lg"
              />
            ) : (
              <div className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg">
                {profile.partner_details?.company_number}
              </div>
            )}
          </div>
        </div>

        {/* Change Password Section */}
        <div className="flex flex-col items-left justify-left">
          {/* Change Password Section */}
          <div className="flex justify-left mt-6">
            <button
              className="bg-orange-700 text-white py-2 px-6 rounded-lg hover:bg-orange-800 text-sm xl:text-lg font-serif"
              onClick={() => setShowPasswordDialog(true)} // Open dialog when clicked
            >
              Update Password
            </button>

            {/* Password Update Dialog */}
            {showPasswordDialog && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                  <h2 className="text-lg font-bold mb-4">Change Password</h2>

                  {/* Old Password Input with Visibility Toggle */}
                  <div className="relative w-full">
                    <label className="relative w-full flex items-left justify-left">Old password</label>
                    <input
                      type={showOldPassword ? "text" : "password"}
                      placeholder="Old Password"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      className="w-full p-2 border rounded mb-2 pr-10"
                    />
                    <button
                      type="button"
                      className="absolute right-2 top-8 text-gray-600"
                      onClick={() => setShowOldPassword(!showOldPassword)}
                    >
                      {showOldPassword ? "👁️" : "🙈"}
                    </button>
                  </div>

                  {/* New Password Input with Visibility Toggle */}
                  <div className="relative w-full">
                    <label className="relative w-full flex items-left justify-left">New password</label>
                    <input
                      type={showNewPassword ? "text" : "password"}
                      placeholder="New Password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="w-full p-2 border rounded mb-2 pr-10"
                    />
                    <button
                      type="button"
                      className="absolute right-2 top-8 text-gray-600"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? "👁️" : "🙈"}
                    </button>
                  </div>

                  {/* Confirm New Password Input with Visibility Toggle */}
                  <div className="relative w-full">
                    <label className="relative w-full flex items-left justify-left">Confirm new password</label>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm New Password"
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                      className="w-full p-2 border rounded mb-2 pr-10"
                    />
                    <button
                      type="button"
                      className="absolute right-2 top-8 text-gray-600"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                      {showConfirmPassword ? "👁️" : "🙈"}
                    </button>
                  </div>

                  {/* Show error if exists */}
                  {passwordError && <p className="text-red-500">{passwordError}</p>}
                  {/* Show success message if password updates */}
                  {passwordSuccess && <p className="text-green-500">{passwordSuccess}</p>}

                  <div className="flex justify-between mt-4">
                    <button
                      onClick={() => setShowPasswordDialog(false)} // Close dialog
                      className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
                    >
                      Cancel
                    </button>

                    <button
                      onClick={handlePasswordUpdate}
                      className="bg-orange-700 text-white py-2 px-4 rounded hover:bg-orange-800"
                      disabled={passwordLoading}
                    >
                      {passwordLoading ? "Updating..." : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-end space-x-4">
          {isEditing ? (
            <>
              <button
                onClick={() => setIsEditing(false)}
                disabled={isSubmitting}
                className="bg-gray-500 text-white py-1.5 px-7 rounded-lg mt-12 mb-5 hover:bg-gray-600 text-xs xl:text-lg font-serif"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                disabled={isSubmitting}
                className="bg-orange-700 text-white py-1.5 px-7 rounded-lg mt-12 mb-5 hover:bg-orange-800 text-xs xl:text-lg font-serif"
              >
                {isSubmitting ? "Saving..." : "Save Changes"}
              </button>
            </>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              className="bg-orange-700 text-white py-1.5 px-7 rounded-lg mt-12 mb-5 hover:bg-orange-800 text-xs xl:text-lg font-serif"
            >
              Update Profile
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileSection;