import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { FiLink } from "react-icons/fi";

const Applicants = ({
  jobApplicants,
  loading,
  getWorkAreaName,
  getVehicleTypeName,
  AcceptingApplicationId,
  removingApplicationId,
  handleRemove,
  handleAccept,
}) => {
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isRejectionOpen, setIsRejectionOpen] = useState(false);
  const [isAlreadyHiredOpen, setIsAlreadyHiredOpen] = useState(false);

  const handleAcceptWithDialog = async (applicationId) => {
    try {
      const result = await handleAccept(applicationId);
      // console.log("Response in Applicants:", result);

      if (result && result.success) {
        setIsSuccessOpen(true);
      } else {
        setIsAlreadyHiredOpen(true);
      }
    } catch (error) {
      console.error("Error in handleAcceptWithDialog:", error);
      setIsAlreadyHiredOpen(true);
    }
  };

  const handleRemoveWithDialog = async (applicationId) => {
    try {
      await handleRemove(applicationId);
      setIsRejectionOpen(true);
    } catch (error) {
      console.error("Error rejecting application:", error);
    }
  };

  return (
    <div className="bg-white bg-opacity-60 rounded-xl mb-8 mt-2 shadow-lg py-4 h-[80vh] md:h-[60vh] w-[90%] flex flex-col items-start justify-start overflow-y-auto custom-scrollbar">
      {/* Success Dialog */}
      <Dialog
        open={isSuccessOpen}
        onClose={() => setIsSuccessOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto w-full max-w-md rounded-lg bg-white">
            <div className="p-8">
              <div className="flex items-center justify-center mb-4">
                <svg
                  className="w-12 h-12 text-green-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <Dialog.Title className="text-2xl font-bold text-center text-gray-800 mb-4">
                Driver Hired!
              </Dialog.Title>
              <p className="text-center text-gray-600 mb-6">
                Your driver's details have been successfully moved to the My
                Drivers tab for better management.
              </p>
              <div className="flex justify-center">
                <button
                  onClick={() => setIsSuccessOpen(false)}
                  className="bg-green-600 text-white py-2 px-6 rounded-md hover:bg-green-700 transition-colors"
                >
                  View My Drivers
                </button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Rejection Dialog */}
      <Dialog
        open={isRejectionOpen}
        onClose={() => setIsRejectionOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto w-full max-w-md rounded-lg bg-white">
            <div className="p-8">
              <div className="flex items-center justify-center mb-4">
                <svg
                  className="w-12 h-12 text-red-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <Dialog.Title className="text-2xl font-bold text-center text-gray-800 mb-4">
                Application Rejected
              </Dialog.Title>
              <p className="text-center text-gray-600 mb-6">
                The application has been rejected successfully.
              </p>
              <div className="flex justify-center">
                <button
                  onClick={() => setIsRejectionOpen(false)}
                  className="bg-red-600 text-white py-2 px-6 rounded-md hover:bg-red-700 transition-colors"
                >
                  Close
                </button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
      {/* Already Hired Dialog */}
      <Dialog
        open={isAlreadyHiredOpen}
        onClose={() => setIsAlreadyHiredOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto w-full max-w-md rounded-lg bg-white">
            <div className="p-8">
              <div className="flex items-center justify-center mb-4">
                <svg
                  className="w-12 h-12 text-yellow-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <Dialog.Title className="text-2xl font-bold text-center text-gray-800 mb-4">
                Driver Already Hired
              </Dialog.Title>
              <p className="text-center text-gray-600 mb-6">
                This driver has already been hired for Another job position by
                you.
              </p>
              <div className="flex justify-center">
                <button
                  onClick={() => setIsAlreadyHiredOpen(false)}
                  className="bg-yellow-600 text-white py-2 px-6 rounded-md hover:bg-yellow-700 transition-colors"
                >
                  Close
                </button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Applicants List */}
      {jobApplicants.length > 0 ? (
        jobApplicants.map((jobApplicant) => (
          <div
            key={`${jobApplicant.id}-${jobApplicant.driver.job_id}`}
            className="flex flex-col md:flex-row md:justify-center md:items-center p-4 md:py-4 xl:p-5 bg-gray-300 w-[90%] mt-4 mx-4 rounded-xl"
          >
            <div className="xl:gap-6 md:gap-4 md:w-[40%]">
              <div className="bg-gray-300 w-[90%] py-1 px-1 md:px-6 flex flex-col items-start justify-start rounded-xl gap-1 relative">
                <p className="md:text-xl text-sm xl:text-2xl text-left font-semibold font-serif mr-2">
                  JOB ID:{" "}
                  <span className="text-red-500">{jobApplicant.job.id}</span>
                </p>
                <p className="text-xs xl:text-xl font-semibold font-serif mr-2">
                  Name:{" "}
                  <span className="font-thin">
                    {jobApplicant.driver.first_name}{" "}
                    {jobApplicant.driver.last_name}
                  </span>
                </p>
                <p className="text-xs xl:text-xl text-start font-semibold font-serif mr-2">
                  Email:{" "}
                  <span className="font-thin">{jobApplicant.driver.email}</span>
                </p>
                <p className="text-xs xl:text-xl font-semibold font-serif mr-2">
                  Phone No:{" "}
                  <span className="font-thin">
                    {jobApplicant.driver.mobile_number}
                  </span>
                </p>
                <p className="text-xs xl:text-xl text-left font-semibold font-serif mr-2">
                  Vehicle Licence:{" "}
                  <span className="font-thin">
                    {getVehicleTypeName(jobApplicant.driver.vehicle_type)}
                  </span>
                </p>
                <p className="text-xs xl:text-xl text-left font-semibold font-serif mr-2">
                  Work Area:{" "}
                  <span className="font-thin">
                    {getWorkAreaName(jobApplicant.driver.work_area)}
                  </span>
                </p>
              </div>
            </div>

            {/* Documents Section */}
            <div className="flex flex-col items-start py-2 mt-1 md:w-[40%]">
              <p className="font-serif font-semibold text-sm xl:text-lg underline mb-2">
                APPLICANT DOCUMENTATION
              </p>
              <p className="font-serif font-thin xl:text-lg text-xs mb-2">
                Click link to download:
              </p>
              {/* Document links */}
              {[
                { label: "ID Card", url: jobApplicant.driver.id_photo },
                { label: "PSV PHOTO", url: jobApplicant.driver.psv_photo },
                {
                  label: "LICENSE PHOTO",
                  url: jobApplicant.driver.license_photo,
                },
                {
                  label: "CERTIFICATE OF GOOD CONDUCT",
                  url: jobApplicant.driver.good_conduct_photo,
                },
              ].map((doc) => (
                <div key={doc.label} className="flex">
                  <span className="text-orange-700 mr-2">
                    <FiLink size={12} />
                  </span>
                  <a
                    href={doc.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-orange-700 text-xs xl:text-lg font-semibold font-serif mr-2"
                  >
                    {doc.label}
                  </a>
                </div>
              ))}
            </div>

            {/* Action Buttons */}
            <div className="md:w-[20%] flex flex-col gap-3 items-baseline md:mt-20 xl:mt-24 mb-5 md:mb-0 justify-end">
              <button
                className="px-7 py-1 bg-green-700 text-xs text-white rounded-lg hover:bg-green-800 transition duration-300"
                onClick={() => handleAcceptWithDialog(jobApplicant.id)}
                disabled={AcceptingApplicationId === jobApplicant.id}
              >
                {AcceptingApplicationId === jobApplicant.id
                  ? "Hiring..."
                  : "Hire Applicant"}
              </button>
              <button
                className="px-7 py-1 bg-orange-700 text-xs text-white rounded-lg hover:bg-orange-800 transition duration-300"
                onClick={() => handleRemoveWithDialog(jobApplicant.id)}
                disabled={removingApplicationId === jobApplicant.id}
              >
                {removingApplicationId === jobApplicant.id
                  ? "Rejecting..."
                  : "Reject Applicant"}
              </button>
            </div>
          </div>
        ))
      ) : (
        <div className="w-full flex items-center justify-center h-[80%]">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <p className="text-black text-lg xl:text-2xl font-serif font-bold">
              No job applicants.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default Applicants;
