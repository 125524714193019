import React from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../spinner/Spinner";

const PostedJobs = ({
  jobData,
  loading,
  getWorkAreaName,
  getVehicleMakeName,
  getVehicleModelName,
  closingJobId,
  handleCloseJobClick,
  handleCloseJob,
  showConfirmDialog,
  setShowConfirmDialog,
}) => {
  const navigate = useNavigate();

  const handleEditJob = (jobId) => {
    navigate(`/editjob/${jobId}`);
  };

  return (
    <div className="bg-white bg-opacity-60 rounded-xl mb-8 mt-2 shadow-lg py-4 h-[80vh] md:h-[60vh] w-[90%] flex flex-col items-start justify-start overflow-y-auto custom-scrollbar">
      {/* Confirmation Dialog */}
      {showConfirmDialog && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-lg font-bold mb-4">Are you sure?</h2>
            <p className="mb-4">Do you really want to close this job?</p>
            <div className="flex justify-center space-x-4">
              <button
                className="bg-red-600 text-white px-4 py-2 rounded-lg"
                onClick={handleCloseJob}
              >
                Yes, Close Job
              </button>
              <button
                className="bg-gray-300 px-4 py-2 rounded-lg"
                onClick={() => setShowConfirmDialog(false)}
              >
                No, Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {jobData && Array.isArray(jobData) && jobData.length > 0 ? (
        jobData.map((job) => (
          <div
            key={job.id}
            className="flex flex-col md:flex-row justify-center items-center xl:p-5 bg-gray-300 w-[90%] mt-4 mx-4 rounded-xl"
          >
            <div className="md:w-2/3 flex flex-col items-start p-2">
              <div className="bg-gray-300 w-full p-2 flex flex-col space-y-2 items-start rounded-xl relative">
                <p className="text-sm xl:text-xl font-semibold font-serif mr-2">
                  Job ID:{" "}
                  <span className="text-red-500 mr-2">
                    {job.id || "Unknown ID"}
                  </span>
                  <span className="w-3 h-3 bg-green-500 rounded-full inline-block mr-2"></span>
                </p>
                <p className="font-serif text-xs xl:text-lg font-semibold mt-1 underline">
                  Job Summary
                </p>
                <div className="text-xs xl:text-lg font-serif mr-2 flex items-center">
                  <span className="font-bold mr-2">Work Area:</span>{" "}
                  {getWorkAreaName(job.preferred_work_area)}
                </div>
                <div className="text-xs xl:text-lg font-serif mr-2 flex items-center">
                  <span className="font-bold mr-2">Vehicle Make:</span>{" "}
                  {getVehicleMakeName(job.vehicle_make)}
                </div>
                <div className="text-xs xl:text-lg font-serif mr-2 flex items-center">
                  <span className="font-bold mr-2">Vehicle Model:</span>{" "}
                  {getVehicleModelName(job.vehicle_model)}
                </div>
                <div className="text-xs flex-col xl:text-lg text-left font-serif mr-2 flex">
                  <span className="font-bold mr-2">Requirements:</span>{" "}
                  {job.requirements}
                </div>
              </div>
            </div>
            <div className="flex flex-col m-4 md:items-end md:justify-end p-2 w-full mt-1 md:w-1/3">
              <div className="p-2 bg-gray-400 rounded-lg relative md:w-[60%] w-full">
                <img
                  src={job.vehicle_photo || "/assets/default-car.png"}
                  alt="Vehicle"
                  className="w-[100%] h-auto xl:w-[250px] xl:h-[250px] md:w-[180px] md:h-[180px] rounded-lg object-cover"
                />
              </div>
              <div className="flex flex-row space-x-4 mt-3">

                {/* Edit Job Button */}
                <button
                  className="font-serif text-white py-1 xl:text-lg px-2 bg-orange-700 rounded-lg mx-4 text-xs mt-3 cursor-pointer"
                  onClick={() => handleEditJob(job.id)}
                >
                  View Job
                </button>
                {/* Close Job Button */}

                <button
                  className="font-serif text-white py-1 xl:text-lg px-2 bg-orange-700 rounded-lg mx-4 text-xs mt-3 cursor-pointer"
                  onClick={(event) => handleCloseJobClick(event, job.id)}
                  disabled={closingJobId === job.id}
                >
                  {closingJobId === job.id ? "Closing..." : "Close Job"}
                </button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="w-full flex items-center justify-center h-[80%]">
          {loading ? (
            <Spinner />
          ) : (
            <p className="text-black text-lg xl:text-2xl font-serif font-bold">
              You currently have no jobs posted.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default PostedJobs;

