import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import NavBarThree from "../navbar/NavBarThree";
import Footer from "../footer/Footer";
import api from "../../services/api";
import Spinner from "../spinner/Spinner";
import axios from "axios";
import { BASE_URL } from "../../services/config";

function DriverDashboard() {
  const [driverData, setDriverData] = useState(null);
  const [workAreas, setWorkAreas] = useState([]);
  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeSection, setActiveSection] = useState("applications");
  const [jobApplicants, setJobApplicants] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);


  // For file uploads
  const [formData, setFormData] = useState({
    id_photo: null,
    psv_photo: null,
    license_photo: null,
    good_conduct_photo: null,
  });

  const [withdrawingApplicationId, setWithdrawingApplicationId] = useState(null);

  // NEW: State for editing profile details
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [profileFormData, setProfileFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    date_of_birth: "",
    id_number: "",
    work_area: "",
    vehicle_type: "",
  });

  //Declare state inside the component
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState(""); // New confirm password state
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const [passwordSuccess, setPasswordSuccess] = useState(null);
  // Password visibility states
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);


  const handlePasswordUpdate = async () => {
    setPasswordLoading(true);
    setPasswordError(null);
    setPasswordSuccess(null);

    let errors = [];

    // Frontend Validation
    if (newPassword.length < 8) {
      errors.push("New password must be at least 8 characters long.");
    }

    if (newPassword !== confirmNewPassword) {
      errors.push("Confirm new password field should match new password field.");
    }

    if (errors.length > 0) {
      setPasswordError(errors.join("\n"));
      setPasswordLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No authentication token found. Please log in again.");
      }

      const response = await axios.post(
        `${BASE_URL}api/update-password`,
        {
          current_password: oldPassword,
          new_password: newPassword,
          confirm_password: confirmNewPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setPasswordSuccess("Password updated successfully!");
        setTimeout(() => {
          setPasswordSuccess("");
        }, 2000);

        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");

        setTimeout(() => {
          setShowPasswordDialog(false); // Close dialog after success
        }, 2000);
      }
    } catch (err) {
      let errorMessage = "Failed to update password.";

      if (err.response) {
        // Handle Django validation errors
        if (err.response.data.detail) {
          if (Array.isArray(err.response.data.detail)) {
            errorMessage = err.response.data.detail.join("\n"); // Join multiple errors
          } else {
            errorMessage = err.response.data.detail;
          }
        }
      } else if (err.message) {
        errorMessage = err.message; // Handle general errors
      }

      setPasswordError(errorMessage);
    } finally {
      setPasswordLoading(false);
    }
  };

  // For picking the profile section when navigating from another page
  useEffect(() => {
    if (location.state?.activeSection) {
      setActiveSection(location.state.activeSection);
    }
  }, [location.state]);

  useEffect(() => {
    const fetchDriverData = async () => {
      try {
        const data = await api.get("/api/users/me/");
        setDriverData(data);

        const workAreasData = await api.get("/api/work-areas/");
        setWorkAreas(workAreasData);

        const vehicleTypesData = await api.get("/api/vehicle-types/");
        setVehicleTypes(vehicleTypesData);

        const vehicleMakesData = await api.get("/api/vehicle-makes/");
        setVehicleMakes(vehicleMakesData);

        const vehicleModelsData = await api.get("/api/vehicle-models/");
        setVehicleModels(vehicleModelsData);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching driver data:", error);
        setLoading(false);
      }
    };

    fetchDriverData();
  }, []);

  // Initialize profileFormData when driverData loads
  useEffect(() => {
    if (driverData) {
      setProfileFormData({
        first_name: driverData.first_name || "",
        last_name: driverData.last_name || "",
        email: driverData.email || "",
        mobile_number: driverData.driver_details?.mobile_number || "",
        date_of_birth: driverData.driver_details?.date_of_birth || "",
        id_number: driverData.driver_details?.id_number || "",
        work_area: driverData.driver_details?.work_area || "",
        vehicle_type: driverData.driver_details?.vehicle_type || "",
        password: driverData.password || "",
      });
    }
  }, [driverData]);

  // Applications
  useEffect(() => {
    const fetchJobApplicants = async () => {
      try {
        const response = await api.get("/api/applications/");
        console.log("Full API Response: ", response);

        const applicants = response;

        if (Array.isArray(applicants)) {
          setJobApplicants(applicants);
          console.log("Job Applicants: ", applicants);
        } else {
          console.error("API Response is not an array:", applicants);
        }
      } catch (error) {
        console.error("Error fetching job applicants: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobApplicants();
  }, []);


  const getWorkAreaName = (id) => {
    const workArea = workAreas.find((area) => area.id === id);
    return workArea ? workArea.name : "Unknown";
  };

  const getVehicleTypeName = (id) => {
    const vehicleType = vehicleTypes.find((type) => type.id === id);
    return vehicleType ? vehicleType.name : "Unknown";
  };

  const getVehicleMakeName = (makeId) => {
    const make = vehicleMakes.find((item) => item.id === makeId);
    return make ? make.name : "Unknown Make";
  };

  const getVehicleModelName = (modelId) => {
    const model = vehicleModels.find((item) => item.id === modelId);
    return model ? model.name : "Unknown Model";
  };

  const handleWithdraw = async (applicationId) => {
    setWithdrawingApplicationId(applicationId);
    try {
      await api.post(`/api/applications/${applicationId}/withdraw_by_driver/`);
      const response = await api.get("/api/applications/");
      setJobApplicants(response);
    } catch (error) {
      console.error("Error withdrawing application:", error);
    } finally {
      setWithdrawingApplicationId(null);
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files[0],
    }));
  };

  // Handler for profile input changes
  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [showPopup]);

  // Update profile handler: if already editing, send update; else enable editing
  const handleUpdateProfile = async () => {
    if (isEditingProfile) {
      setIsUpdating(true); //
      try {
        const token = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refresh_token");

        const usetoken = token || refreshToken;
        if (!usetoken) {
          throw new Error("No token found. Please log in again.");
        }
        // Using an endpoint that updates the current driver's profile
        const userData = await api.get("/api/users/me/");
        const driverId = userData.driver_details.id;

        const updatedData = {
          first_name: profileFormData.first_name,
          last_name: profileFormData.last_name,
          mobile_number: profileFormData.mobile_number,
          date_of_birth: profileFormData.date_of_birth,
          work_area: profileFormData.work_area,
          vehicle_type: profileFormData.vehicle_type,
          password: profileFormData.password,

        }

        const response = await axios.put(
          `${BASE_URL}api/drivers/${driverId}/`,
          updatedData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          setShowPopup(true);
          setIsEditingProfile(false);
          setTimeout(() => {
            window.location.reload();
          }, 6000);
        }
        else {
          alert("Error updating profile.");
        }
      } catch (error) {
        console.error("Error updating profile:", error.response?.data || error);
        alert("Failed to update profile.");
      } finally {
        setIsUpdating(false); // Reset updating state
      }
    } else {
      setIsEditingProfile(true);
    }
  };

  // Cancel editing: reset form to original driver data and disable editing
  const handleCancelEditing = () => {
    if (driverData) {
      setProfileFormData({
        first_name: driverData.first_name || "",
        last_name: driverData.last_name || "",
        email: driverData.email || "",
        mobile_number: driverData.driver_details?.mobile_number || "",
        date_of_birth: driverData.driver_details?.date_of_birth || "",
        id_number: driverData.driver_details?.id_number || "",
        work_area: driverData.driver_details?.work_area || "",
        vehicle_type: driverData.driver_details?.vehicle_type || "",
        password: driverData.password || "",
      });
    }
    setIsEditingProfile(false);
  };

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className="flex flex-col min-h-screen bg-cover max-w-full"
      style={{ backgroundImage: "url(/assets/background3.svg)" }}
    >
      <NavBarThree />

      <div className="flex flex-col flex-grow w-full items-center justify-center">
        <div className="bg-white relative bg-opacity-50 rounded-xl mt-6 shadow-lg px-2 md:px-4 m-4 md:mx-8 w-[90%]">
          <div className="text-center absolute -top-4 left-1/2 transform -translate-x-1/2">
            <img
              src="/assets/profile2.png"
              alt="Profile"
              className="mx-auto mb-1 w-[24%] md:w-[8%] h-auto"
            />
          </div>
          <div className="mt-12 xl:mt-16">
            <p className="xl:text-xl md:text-sm text-xs">
              {driverData?.role
                ? driverData.role.charAt(0).toUpperCase() +
                driverData.role.slice(1)
                : "Loading..."}
            </p>
            <h2 className="text-sm md:text-xl xl:text-2xl font-bold font-serif text-black mb-1">
              {/* {`${driverData?.first_name} ${driverData?.last_name}`} */}
              {loading ? "Loading..." : `${driverData?.first_name} ${driverData?.last_name}`}
            </h2>

            <div className="flex md:space-x-16 space-x-4 items-center justify-center mt-3">
              <button
                className={`py-2 px-4 text-xs md:text-sm font-bold font-serif rounded-t ${activeSection === "applications"
                  ? "bg-orange-700 text-white"
                  : "bg-gray-300 text-black"
                  }`}
                onClick={() => setActiveSection("applications")}
              >
                APPLICATIONS
              </button>
              <button
                className={`py-2 md:px-6 px-4 text-xs md:text-sm font-bold font-serif rounded-t ${activeSection === "profile"
                  ? "bg-orange-700 text-white"
                  : "bg-gray-300 text-black"
                  }`}
                onClick={() => setActiveSection("profile")}
              >
                MY PROFILE
              </button>
            </div>
          </div>
        </div>
        {activeSection === "profile" && (
          <div className="bg-white bg-opacity-50 md:p-5 p-2 flex flex-col rounded-xl w-[90%] ">

            {/* Parent Flex Container for Profile Details and File Uploads */}
            <div className="mt-4 flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-2 md:px-2 xl:py-6">
              {/* Left Column: Profile Details */}
              <div className="flex-1 space-y-4">
                {/* Profile Details Grids */}
                {showPopup && (
                  <div className="fixed top-26 font-serif left-1/2 transform -translate-x-1/2 bg-green-500 text-white p-4 rounded shadow-md z-50">
                    Profile updated successfully
                  </div>
                )}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {/* First Name */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="first_name"
                      className="mb-1 text-black uppercase text-left xl:text-lg text-xs font-serif"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      id="first_name"
                      name="first_name"
                      value={profileFormData.first_name}
                      onChange={handleProfileChange}
                      disabled={!isEditingProfile}
                      className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg"
                    />
                  </div>
                  {/* Last Name */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="last_name"
                      className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="last_name"
                      name="last_name"
                      value={profileFormData.last_name}
                      onChange={handleProfileChange}
                      disabled={!isEditingProfile}
                      className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg"
                    />
                  </div>
                  {/* Email (always disabled) */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="email"
                      className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={profileFormData.email}
                      disabled
                      className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {/* Mobile Number */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="mobile_number"
                      className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif"
                    >
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      id="mobile_number"
                      name="mobile_number"
                      value={profileFormData.mobile_number}
                      onChange={handleProfileChange}
                      disabled={!isEditingProfile}
                      className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg"
                    />
                  </div>
                  {/* Date of Birth */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="date_of_birth"
                      className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif"
                    >
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      id="date_of_birth"
                      name="date_of_birth"
                      value={profileFormData.date_of_birth}
                      onChange={handleProfileChange}
                      disabled={!isEditingProfile}
                      className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg"
                    />
                  </div>
                  {/* ID Number */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="id_number"
                      className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif"
                    >
                      ID Number
                    </label>
                    <input
                      type="text"
                      id="id_number"
                      name="id_number"
                      value={profileFormData.id_number}
                      onChange={handleProfileChange}
                      disabled={!isEditingProfile}
                      className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                  {/* Work Area */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="work_area"
                      className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif"
                    >
                      Work Area
                    </label>
                    {isEditingProfile ? (
                      <select
                        id="work_area"
                        name="work_area"
                        value={profileFormData.work_area}
                        onChange={handleProfileChange}
                        className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left rounded-lg"
                      >
                        <option value="">Select</option>
                        {workAreas.map((area) => (
                          <option key={area.id} value={area.id}>
                            {area.name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        id="work_area"
                        name="work_area"
                        value={getWorkAreaName(driverData.driver_details.work_area)}
                        disabled
                        className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg"
                      />
                    )}
                  </div>
                  {/* Licensed Vehicle */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="vehicle_type"
                      className="mb-1 text-black uppercase text-left text-xs xl:text-lg font-serif"
                    >
                      Licensed Vehicle
                    </label>
                    {isEditingProfile ? (
                      <select
                        id="vehicle_type"
                        name="vehicle_type"
                        value={profileFormData.vehicle_type}
                        onChange={handleProfileChange}
                        className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left rounded-lg"
                      >
                        <option value="">Select</option>
                        {vehicleTypes.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        id="vehicle_type"
                        name="vehicle_type"
                        value={getVehicleTypeName(driverData.driver_details.vehicle_type)}
                        disabled
                        className="p-1.5 xl:text-lg text-xs border border-gray-300 text-left bg-gray-300 rounded-lg"
                      />
                    )}
                  </div>
                </div>
              </div>

              {/* Right Column: File Upload Sections */}
              <div className="flex-2 space-y-6">
                {/* ID Photo */}
                <div className="flex flex-col">
                  <label
                    htmlFor="idPhoto"
                    className="mb-1 text-black text-left uppercase text-xs font-serif font-bold"
                  >
                    ID Photo
                  </label>
                  <div className="flex items-center flex-wrap">
                    <label
                      htmlFor="idPhoto"
                      className="bg-gray-500 shadow-md border border-gray-500 px-2 py-1 text-white text-xs font-serif cursor-pointer hover:bg-gray-400 rounded-lg"
                    >
                      CHOOSE FILE
                      <input
                        type="file"
                        id="idPhoto"
                        name="id_photo"
                        accept="image/*"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </label>
                    <span className="ml-2 text-black text-xs mt-2 sm:mt-0">
                      {formData.id_photo ? (
                        formData.id_photo.name
                      ) : (
                        <a
                          href={driverData.driver_details.id_photo}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-orange-700 underline"
                        >
                          View Photo
                        </a>
                      )}
                    </span>
                  </div>
                </div>

                {/* PSV Photo */}
                <div className="flex flex-col">
                  <label
                    htmlFor="psvPhoto"
                    className="mb-1 text-black text-left uppercase text-xs font-serif font-bold"
                  >
                    PSV Photo
                  </label>
                  <div className="flex items-center flex-wrap">
                    <label
                      htmlFor="psvPhoto"
                      className="bg-gray-500 shadow-md border border-gray-500 px-2 py-1 text-white text-xs font-serif cursor-pointer hover:bg-gray-400 rounded-lg"
                    >
                      CHOOSE FILE
                      <input
                        type="file"
                        id="psvPhoto"
                        name="psv_photo"
                        accept="image/*"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </label>
                    <span className="ml-2 text-black text-xs mt-2 sm:mt-0">
                      {formData.psv_photo ? (
                        formData.psv_photo.name
                      ) : (
                        <a
                          href={driverData.driver_details.psv_photo}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-orange-700 underline"
                        >
                          View Photo
                        </a>
                      )}
                    </span>
                  </div>
                </div>

                {/* License Photo */}
                <div className="flex flex-col">
                  <label
                    htmlFor="licensePhoto"
                    className="mb-1 text-black text-left uppercase text-xs font-serif font-bold"
                  >
                    License Photo
                  </label>
                  <div className="flex items-center flex-wrap">
                    <label
                      htmlFor="licensePhoto"
                      className="bg-gray-500 shadow-md border border-gray-500 px-2 py-1 text-white text-xs font-serif cursor-pointer hover:bg-gray-400 rounded-lg"
                    >
                      CHOOSE FILE
                      <input
                        type="file"
                        id="licensePhoto"
                        name="license_photo"
                        accept="image/*"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </label>
                    <span className="ml-2 text-black text-xs mt-2 sm:mt-0">
                      {formData.license_photo ? (
                        formData.license_photo.name
                      ) : (
                        <a
                          href={driverData.driver_details.license_photo}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-orange-700 underline"
                        >
                          View Photo
                        </a>
                      )}
                    </span>
                  </div>
                </div>

                {/* Good Conduct */}
                <div className="flex flex-col">
                  <label
                    htmlFor="conductPhoto"
                    className="mb-1 text-black text-left uppercase text-xs font-serif font-bold"
                  >
                    Good Conduct
                  </label>
                  <div className="flex items-center flex-wrap">
                    <label
                      htmlFor="conductPhoto"
                      className="bg-gray-500 shadow-md border border-gray-500 px-2 py-1 text-white text-xs font-serif cursor-pointer hover:bg-gray-400 rounded-lg"
                    >
                      CHOOSE FILE
                      <input
                        type="file"
                        id="conductPhoto"
                        name="good_conduct_photo"
                        accept="image/*"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </label>
                    <span className="ml-2 text-black text-xs mt-2 sm:mt-0">
                      {formData.good_conduct_photo ? (
                        formData.good_conduct_photo.name
                      ) : (
                        <a
                          href={driverData.driver_details.good_conduct_photo}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-orange-700 underline"
                        >
                          View Photo
                        </a>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Change Password Section */}
            <div className="flex flex-col items-left justify-left">
              {/* Change Password Section */}
              <div className="flex justify-left mt-6">
                <button
                  className="bg-orange-700 text-white py-2 px-6 rounded-lg hover:bg-orange-800 text-sm xl:text-lg font-serif"
                  onClick={() => setShowPasswordDialog(true)} // Open dialog when clicked
                >
                  Update Password
                </button>

                {/* Password Update Dialog */}
                {showPasswordDialog && (
                  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                      <h2 className="text-lg font-bold mb-4">Change Password</h2>

                      {/* Old Password Input with Visibility Toggle */}
                      <div className="relative w-full">
                        <input
                          type={showOldPassword ? "text" : "password"}
                          placeholder="Old Password"
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                          className="w-full p-2 border rounded mb-2 pr-10"
                        />
                        <button
                          type="button"
                          className="absolute right-2 top-3 text-gray-600"
                          onClick={() => setShowOldPassword(!showOldPassword)}
                        >
                          {showOldPassword ? "👁️" : "🙈"}
                        </button>
                      </div>

                      {/* New Password Input with Visibility Toggle */}
                      <div className="relative w-full">
                        <input
                          type={showNewPassword ? "text" : "password"}
                          placeholder="New Password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          className="w-full p-2 border rounded mb-2 pr-10"
                        />
                        <button
                          type="button"
                          className="absolute right-2 top-3 text-gray-600"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        >
                          {showNewPassword ? "👁️" : "🙈"}
                        </button>
                      </div>

                      {/* Confirm New Password Input with Visibility Toggle */}
                      <div className="relative w-full">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Confirm New Password"
                          value={confirmNewPassword}
                          onChange={(e) => setConfirmNewPassword(e.target.value)}
                          className="w-full p-2 border rounded mb-2 pr-10"
                        />
                        <button
                          type="button"
                          className="absolute right-2 top-3 text-gray-600"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                          {showConfirmPassword ? "👁️" : "🙈"}
                        </button>
                      </div>

                      {/* Show error if exists */}
                      {passwordError && <p className="text-red-500">{passwordError}</p>}
                      {/* Show success message if password updates */}
                      {passwordSuccess && <p className="text-green-500">{passwordSuccess}</p>}

                      <div className="flex justify-between mt-4">
                        <button
                          onClick={() => setShowPasswordDialog(false)} // Close dialog
                          className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
                        >
                          Cancel
                        </button>

                        <button
                          onClick={handlePasswordUpdate}
                          className="bg-orange-700 text-white py-2 px-4 rounded hover:bg-orange-800"
                          disabled={passwordLoading}
                        >
                          {passwordLoading ? "Updating..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Buttons for Update/Cancel */}
            <div className="flex justify-center">
              {isEditingProfile ? (
                <>
                  <button
                    type="button"
                    className="bg-orange-700 text-white py-2 px-8 rounded-lg mt-12 mr-8 mb-5 hover:bg-orange-800 text-sm xl:text-lg font-serif w-48 sm:w-64 md:w-80 lg:w-96"
                    onClick={handleUpdateProfile}
                  >
                    {isUpdating ? "Updating Profile..." : "Update Profile"}
                  </button>
                  <button
                    type="button"
                    className="bg-gray-500 text-white py-2 px-8 rounded-lg mt-12 mb-5 hover:bg-gray-600 text-sm xl:text-lg font-serif w-48 sm:w-64 md:w-80 lg:w-96"
                    onClick={handleCancelEditing}
                  >
                    Cancel Changes
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="bg-orange-700 text-white py-2 px-8 rounded-lg mt-12 mr-8 mb-5 hover:bg-orange-800 text-sm xl:text-lg font-serif w-48 sm:w-64 md:w-80 lg:w-96"
                  onClick={() => setIsEditingProfile(true)}
                >
                  Update Profile
                </button>
              )}
            </div>
          </div>
        )}

        {activeSection === "applications" && (
          <div className="bg-white bg-opacity-60 h-[80vh] md:h-[60vh] rounded-xl mb-8 shadow-lg py-4 flex flex-col items-start justify-start px-2 w-[90%] overflow-y-auto custom-scrollbar">
            {jobApplicants.length > 0 ? (
              jobApplicants.map((jobApplicant) => (
                <div
                  key={jobApplicant.id}
                  className="flex flex-col sm:flex-row justify-center items-center p-4 bg-gray-300 w-full sm:w-[90%] mt-4 mx-auto rounded-xl"
                >
                  {/* Job Details */}
                  <div className="w-full sm:w-1/2">
                    <div className="bg-gray-300 w-full p-4 flex flex-col items-start rounded-xl gap-2">
                      <p className="text-sm xl:text-xl font-semibold font-serif mr-2">
                        JOB ID:{" "}
                        <span className="text-orange-500">
                          {jobApplicant.job.id}
                        </span>
                      </p>
                      <div className="flex items-center">
                        {jobApplicant.status === "active" ? (
                          <>
                            <span className="w-3 h-3 bg-green-500 rounded-full inline-block mr-2"></span>
                            <p className="text-xs xl:text-xl font-serif text-green-500">
                              Active
                            </p>
                          </>
                        ) : jobApplicant.status === "rejected" ? (
                          <>
                            <span className="w-3 h-3 bg-red-500 rounded-full inline-block mr-2"></span>
                            <p className="text-xs xl:text-xl font-serif text-red-500">
                              Rejected
                            </p>
                          </>
                        ) : jobApplicant.status === "hired" ? (
                          <>
                            <span className="w-3 h-3 bg-blue-500 rounded-full inline-block mr-2"></span>
                            <p className="text-xs xl:text-xl font-serif text-blue-500">
                              Hired
                            </p>
                          </>
                        ) : jobApplicant.status === "unhired" ? (
                          <>
                            <span className="w-3 h-3 bg-red-800 rounded-full inline-block mr-2"></span>
                            <p className="text-xs xl:text-xl font-serif text-red-800">
                              Unhired
                            </p>
                          </>
                        ) : (
                          <>
                            <span className="w-3 h-3 bg-gray-500 rounded-full inline-block mr-2"></span>
                            <p className="text-xs xl:text-xl font-serif text-gray-500">
                              Unknown
                            </p>
                          </>
                        )}
                      </div>
                      <h2 className="text-xs xl:text-xl font-serif mt-2">
                        JOB DETAILS
                      </h2>
                      <p className="font-serif text-black text-xs mt-1">
                        <strong>Vehicle Make:</strong>{" "}
                        {getVehicleMakeName(jobApplicant.job.vehicle_make)}
                      </p>
                      <p className="font-serif text-black text-xs mt-1">
                        <strong>Vehicle Model:</strong>{" "}
                        {getVehicleModelName(jobApplicant.job.vehicle_model)}
                      </p>
                      <p className="font-serif text-black text-xs mt-1">
                        <strong>Location:</strong>{" "}
                        {getWorkAreaName(jobApplicant.job.preferred_work_area)}
                      </p>
                      <p className="font-serif text-black text-xs mt-1 text-left">
                        <strong>Requirements:</strong>{" "}
                        {jobApplicant.job.requirements}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col items-left sm:items-end py-2 mt-4 w-full sm:w-1/2">
                    <div className="w-auto bg-gray-400 rounded-lg p-2">
                      <div className="bg-white rounded-lg p-2">
                        <img
                          src={jobApplicant.job.vehicle_photo}
                          alt="Car"
                          className="w-40 h-auto mx-auto"
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <button
                        className="px-7 py-1 bg-orange-700 text-xs text-white rounded-lg hover:bg-orange-800 transition duration-300"
                        onClick={() => handleWithdraw(jobApplicant.id)}
                        disabled={withdrawingApplicationId === jobApplicant.id}
                      >
                        {withdrawingApplicationId === jobApplicant.id
                          ? "Withdrawing..."
                          : "Withdraw"}
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full flex items-center justify-center h-[80%]">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <p className="text-black text-lg xl:text-2xl font-serif font-bold">
                    No job applications.
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default DriverDashboard;
