import React, { useState, useEffect } from "react";
import api from "../../../../services/api";

const PartnerDriver = ({ driverInfo }) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [showPopup]);

  const handleRemoveDriver = async (driverId) => {
    setIsRemoving(true);
    try {
      await api.post(`/api/partner-drivers/${driverId}/unhire/`);
      setShowPopup(true);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error("Error removing driver:", error);
    } finally {
      setIsRemoving(false);
    }
  };

  return (
    <div className="bg-white bg-opacity-40 shadow-sm p-4 pb-8 mb-4 ms-7 me-2 mt-4 flex flex-col md:flex-row justify-start items-start xl:p-5 w-[95%] rounded-xl">
      {showPopup && (
        <div className="fixed top-0 font-serif left-1/2 transform -translate-x-1/2 bg-green-500 text-white p-4 rounded shadow-md z-50">
          Driver removed successfully
        </div>
      )}
      <div className="flex items-start gap-4 flex-col">
        <div className="flex items-center gap-2">
          <span className="text-sm">Driver ID: </span>
          <span className="text-orange-500 font-medium">{driverInfo.id}</span>
          <div className="w-2 h-2 bg-green-500 rounded-full"></div>
        </div>
        {/* contacts & driver documents */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-32">
          {/* Contacts Section */}
          <div className="space-y-2">
            <h3 className="text-sm text-left underline font-bold uppercase text-black mb-3">
              Contacts
            </h3>
            <div className="space-y-3 text-left">
              <div className="flex flex-col">
                <span className="text-xs">
                  Name: {driverInfo.first_name} {driverInfo.last_name}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="text-xs">Email: {driverInfo.email}</span>
              </div>
              <div className="flex flex-col">
                <span className="text-xs">
                  Phone: {driverInfo.mobile_number}
                </span>
              </div>
            </div>
          </div>

          {/* Documents Section */}
          <div className="space-y-2">
            <h3 className="text-sm text-left underline font-bold uppercase mb-3">
              Driver's Documents
            </h3>
            <div className="space-y-3 text-xs">
              <a
                href={driverInfo.id_photo}
                target="_blank"
                rel="noreferrer"
                className="flex items-center gap-2"
              >
                <div className="w-4 h-4 flex items-center justify-center">
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <span>ID PHOTO</span>
              </a>
              <a
                href={driverInfo.psv_photo}
                target="_blank"
                rel="noreferrer"
                className="flex items-center gap-2"
              >
                <div className="w-4 h-4 flex items-center justify-center">
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <span>PSV PHOTO</span>
              </a>
              <a
                href={driverInfo.license_photo}
                target="_blank"
                rel="noreferrer"
                className="flex items-center gap-2"
              >
                <div className="w-4 h-4 flex items-center justify-center">
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <span>DRIVER'S LICENSE</span>
              </a>
              <a
                href={driverInfo.good_conduct_photo}
                target="_blank"
                rel="noreferrer"
                className="flex items-center gap-2"
              >
                <div className="w-4 h-4 flex items-center justify-center">
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <span>GOOD CONDUCT CERTIFICATE</span>
              </a>
            </div>
          </div>
          <div className="flex items-end">
            <button
              className="px-7 py-1 bg-orange-700 text-xs text-white rounded-lg hover:bg-orange-800 transition duration-300"
              onClick={() => handleRemoveDriver(driverInfo.id)}
              disabled={isRemoving}
            >
              {isRemoving ? "Removing..." : "Remove Driver"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerDriver;
