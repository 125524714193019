import React, { useEffect, useState } from "react";
import NavBarTwo from "../navbar/NavBarTwo";
import Footer from "../footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../services/config";

function WelcomePartner() {
  const location = useLocation();
  const navigate = useNavigate();
  const { verificationCode } = useParams();

  const [showPopup, setShowPopup] = useState(!!location.state?.successPopup);
  const [status, setStatus] = useState(null);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const { first_name, email } = location.state?.formData || {};

  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 6000);

      return () => clearTimeout(timer);
    }
  }, [showPopup]);

  // Email verification function
  useEffect(() => {
    const verifyEmail = async () => {
      if (!verificationCode) return;

      setLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}api/verify-email/${verificationCode}/`);
        setStatus(response.data.detail);
        if (response.status === 200) {
          setVerificationSuccess(true);
          setShowPopup(true);
        }
      } catch (error) {
        console.error("Error during verification:", error);
        setStatus("Verification failed.");
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, [verificationCode]);

  // Redirect to login after 3 seconds if verification is successful
  useEffect(() => {
    if (verificationSuccess) {
      const timer = setTimeout(() => {
        navigate("/login");
      }, 7000);

      return () => clearTimeout(timer);
    }
  }, [verificationSuccess, navigate]);

  return (
    <div
      className="flex flex-col min-h-screen bg-cover"
      style={{ backgroundImage: "url(/assets/background3.svg)" }}
    >
      {/* Navbar */}
      <NavBarTwo />

      <div className="flex-grow  relative mt-8 flex justify-center w-full items-center">
        <div className="bg-white bg-opacity-85 rounded-xl mb-8 mt-6 shadow-lg py-12 px-1 md:px-4 md:py-12 w-full max-w-full h-full overflow-y-auto mx-4 md:mx-8">
          {showPopup && (
            <div className="fixed top-26 font-serif left-1/2 transform -translate-x-1/2 bg-green-500 text-white p-4 rounded shadow-md z-50">
              {verificationSuccess
                ? "Email successfully verified! Redirecting to login..."
                : location.state?.successPopup}
            </div>
          )}

          <h1 className="text-black font-serif font-bold md:tracking-wider text-sm xl:text-4xl md:text-2xl my-3">
            Welcome to KaDereConnect,{" "}
            <span className="text-orange-700">{first_name}</span>, <br />
            We're Excited to Have You On Board!!!!!
          </h1>
          <p className="font-serif text-gray-700 text-xs md:text-sm xl:text-lg font-bold md:tracking-wider">
            Your account is almost ready! just verify your email to start
            exploring job opportunities.
          </p>
          <p className="font-serif text-gray-700 text-xs xl:text-sm tracking-tight md:tracking-wider my-3 ">
            We've sent a confirmation link to your email,{" "}
            <span className="text-orange-800">{email}</span>. Please click the
            link to verify <br />
            your email address and activate your account. Didn't get any email?
            No worries, click{" "}
            <span className="text-orange-800 font-bold cursor-pointer underline">
              here
            </span>{" "}
            to resend it.
          </p>

          {loading ? (
            <p className="text-lg font-serif text-gray-600">
              Verifying email...
            </p>
          ) : (
            <>
              {status && (
                <p
                  className={`text-lg font-serif ${
                    verificationSuccess ? "text-green-600" : "text-red-600"
                  }`}
                >
                  {status}
                </p>
              )}
            </>
          )}

          <div className="flex flex-col md:flex-row items-center justify-center">
            <div className="text-center mt-1">
              <img
                src="/assets/emailverification.png"
                alt="Welcome"
                className="mx-auto mb-1 w-[90px] xl:w-[120px] h-auto"
              />
              <p className="font-serif font-bold xl:text-lg text-sm py-1 ">
                {" "}
                Email Confirmation
              </p>
              <p className="font-serif xl:text-sm  text-xs xl:mb-24  md:mb-20">
                Once your email is verified, <br />
                you can log in and get started!
              </p>
            </div>
            <div className="text-sm md:block hidden">
              {"•".repeat(12)}{" "}
              <FontAwesomeIcon icon={faAngleRight} className="" />
            </div>
            <div className="text-5xl md:hidden font-bold">↓</div>

            <div className="text-center mt-1">
              <img
                src="/assets/hiring.png"
                alt="Welcome"
                className="mx-auto mb-1 w-[100px] xl:w-[120px]  h-auto"
              />
              <p className="font-serif font-bold xl:text-lg text-sm py-1">
                {" "}
                Post Job Opportunties
              </p>
              <p className="font-serif xl:text-sm text-xs md:mb-4">
                Once your email is verified log into your <br /> patner
                dashbaord and start posting jobs. <br /> You can outline the
                type of drivers you're <br /> looking for, and include all job
                details to <br /> attract the right candidates.
              </p>
            </div>
            <div className="text-sm mr-2 md:block hidden">
              {"•".repeat(12)}{" "}
              <FontAwesomeIcon icon={faAngleRight} className="" />
            </div>
            <div className="text-5xl md:hidden font-bold">↓</div>
            <div className="text-center mt-1">
              <img
                src="/assets/manageposting.png"
                alt="Welcome"
                className="mx-auto mb-1 w-[80px] xl:w-[120px] h-auto"
              />
              <p className="font-serif font-bold xl:text-lg text-sm py-1">
                {" "}
                Manage Postings
              </p>
              <p className="font-serif  xl:text-sm text-xs xl:mb-24 md:mb-14">
                Track posted Jobs and review job
                <br /> details through your dashboard.
              </p>
            </div>
            <div className="text-sm mr-2 md:block hidden">
              {"•".repeat(12)}{" "}
              <FontAwesomeIcon icon={faAngleRight} className="" />
            </div>
            <div className="text-5xl md:hidden font-bold">↓</div>
            <div className="text-center mt-1">
              <img
                src="/assets/driverss.png"
                alt="Welcome"
                className="mx-auto mb-1 w-[100px] xl:w-[120px] h-auto"
              />
              <p className="font-serif font-bold xl:text-lg text-sm py-1">
                {" "}
                Find the Best Drivers
              </p>
              <p className="font-serif  xl:text-sm text-xs md:mb-12">
                Hire skilled and reliable dribers to meet
                <br /> your transporation needs. Our platform <br /> connects
                you with candidates that are <br /> perfect match for your
                business.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default WelcomePartner;
