import React, { useEffect, useState } from "react";
import NavBarThree from "../navbar/NavBarThree";
import ProfileSection from "./components/partner/ProfileSection";
import Footer from "../footer/Footer";
import PostedJobs from "./components/partner/PostedJobs";
import api from "../../services/api";
import Spinner from "../spinner/Spinner";
import MyDrivers from "./components/partner/MyDrivers";
import Applicants from "./components/partner/Applicants";

function PartnerDashboard() {
  const [profile, setProfile] = useState({});
  const [workAreas, setWorkAreas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [jobData, setJobData] = useState([]);
  const [activeSection, setActiveSection] = useState("postedJobs");
  // const [closingJobId, setClosingJobId] = useState(null);
  // const [jobsPosted, setJobsPosted] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [closingJobId, setClosingJobId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [jobApplicants, setJobApplicants] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  // const [isRemoving, setIsRemoving] = useState(false);
  const [removingApplicationId, setRemovingApplicationId] = useState(null);
  const [acceptingApplicationId, setAcceptingApplicationId] = useState(null);

  // add driver modal state
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachedProfile = localStorage.getItem("profile");
        const cachedWorkAreas = localStorage.getItem("workAreas");
        const cachedJobs = localStorage.getItem("jobs");
        const cachedVehicleMakes = localStorage.getItem("vehicleMakes"); //
        const cachedVehicleModels = localStorage.getItem("vehicleModels");
        const cachedVehicleTypes = localStorage.getItem("vehicleTypes");

        if (cachedProfile && cachedWorkAreas && cachedJobs) {
          setProfile(JSON.parse(cachedProfile));
          setWorkAreas(JSON.parse(cachedWorkAreas));
          setJobData(JSON.parse(cachedJobs));
          setVehicleMakes(JSON.parse(cachedVehicleMakes));
          setVehicleModels(JSON.parse(cachedVehicleModels));
          setVehicleTypes(JSON.parse(cachedVehicleTypes));
        }

        // Fetch from API to ensure data is up-to-date
        const profileData = await api.get("/api/users/me/");
        const workAreasData = await api.get("/api/work-areas/");
        const jobData = await api.get("/api/jobs/");
        const vehicleModelsData = await api.get("/api/vehicle-models/");
        const vehicleMakesData = await api.get("/api/vehicle-makes/");
        const vehicleTypesData = await api.get("/api/vehicle-types/");

        localStorage.setItem("profile", JSON.stringify(profileData));
        localStorage.setItem("workAreas", JSON.stringify(workAreasData));
        localStorage.setItem("jobs", JSON.stringify(jobData));
        localStorage.setItem("vehicleMakes", JSON.stringify(vehicleModelsData));
        localStorage.setItem("vehicleModels", JSON.stringify(vehicleMakesData));
        localStorage.setItem("vehicleTypes", JSON.stringify(vehicleTypesData));

        setProfile(profileData);
        setWorkAreas(workAreasData);
        setJobData(jobData);
        setVehicleMakes(vehicleMakesData);
        setVehicleModels(vehicleModelsData);
        setVehicleTypes(vehicleTypesData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Applicants
  useEffect(() => {
    const fetchJobApplicants = async () => {
      try {
        const response = await api.get("/api/applications/");

        const applicants = response;

        if (Array.isArray(applicants)) {
          setJobApplicants(applicants);
        } else {
          console.error("API Response is not an array:", applicants);
        }
      } catch (error) {
        console.error("Error fetching job applicants: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobApplicants();
  }, []);

  const getWorkAreaName = (id) => {
    const workArea = workAreas.find((area) => area.id === id);
    return workArea ? workArea.name : "Unknown";
  };
  const getVehicleMakeName = (makeId) => {
    const make = vehicleMakes.find((item) => item.id === makeId);
    return make ? make.name : "Unknown Make";
  };

  const getVehicleModelName = (modelId) => {
    const model = vehicleModels.find((item) => item.id === modelId);
    return model ? model.name : "Unknown Model";
  };

  const getVehicleTypeName = (id) => {
    const vehicleType = vehicleTypes.find((type) => type.id === id);
    return vehicleType ? vehicleType.name : "Unknown";
  };

  const handleRemove = async (applicationId) => {
    setRemovingApplicationId(applicationId);
    try {
      await api.post(`/api/applications/${applicationId}/remove_by_partner/`);
      // Update status to "Rejected"
      const updatedApplicants = jobApplicants.map((applicant) =>
        applicant.id === applicationId
          ? { ...applicant, status: "Rejected" }
          : applicant
      );
      setJobApplicants(updatedApplicants);

      // Refetch the applicants list if needed
      const response = await api.get("/api/applications/");
      setJobApplicants(response);
    } catch (error) {
      console.error("Error removing application:", error);
    } finally {
      setRemovingApplicationId(null);
    }
  };
   
  useEffect(() => {
        if (showPopup) {
          const timer = setTimeout(() => {
            setShowPopup(false);
          }, 2000);
          return () => clearTimeout(timer);
        }
  }, [showPopup]);

  const handleAccept = async (applicationId) => {
    setAcceptingApplicationId(applicationId);
    try {
      const response = await api.post(
        `/api/applications/${applicationId}/hire_driver/`
      );
      // console.log("API Response:", response);

      // If the API call is successful
      if (response) {
        // Update local state
        const updatedApplicants = jobApplicants.map((applicant) =>
          applicant.id === applicationId
            ? { ...applicant, status: "Accepted" }
            : applicant
        );
        setJobApplicants(updatedApplicants);

        // Refetch the applicants list
        const newResponse = await api.get("/api/applications/");
        setJobApplicants(newResponse);

        // Return explicit success status
        return { success: true, status: 200 };
      }
      return { success: false, status: 400 };
    } catch (error) {
      console.error("Error accepting application:", error);
      return { success: false, status: 400 };
    } finally {
      setAcceptingApplicationId(null);
    }
  };

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const handleCloseJobClick = (event, jobId) => {
    event.preventDefault();
    setSelectedJobId(jobId); // Store the job ID for confirmation
    setShowConfirmDialog(true); // Show the confirmation dialog
  };
  
  const handleCloseJob = async () => {
    if (!selectedJobId) return;
    setClosingJobId(selectedJobId);
    setShowConfirmDialog(false); // Hide the confirmation dialog
  
    try {
      await api.post(`/api/jobs/${selectedJobId}/close_job/`);
      const updatedJobData = jobData.filter((job) => job.id !== selectedJobId);
      setJobData(updatedJobData);
      setActiveSection("postedJobs");
      setShowPopup(true);
    } catch (error) {
      console.error("Error closing job:", error);
      alert("Failed to close job. Please try again.");
    } finally {
      setClosingJobId(null);
      setSelectedJobId(null);
    }
  };
  
  return (
    <div
      className="flex flex-col min-h-screen bg-cover max-w-full"
      style={{ backgroundImage: "url(/assets/background3.svg)" }}
    >
       {showPopup && (
        <div className="fixed top-26 font-serif left-1/2 transform -translate-x-1/2 bg-green-500 text-white p-4 rounded shadow-md z-50">
          Job closed successfully
        </div>
      )}
      <NavBarThree />
      <div className="flex flex-col flex-grow w-full items-center justify-center">
        <div className="bg-white relative bg-opacity-50 rounded-xl mt-3 shadow-lg px-2  md:px-4 m-4 md:mx-8 w-[90%]">
          <div className="text-center absolute -top-4 left-1/2 transform -translate-x-1/2">
            <img
              src="/assets/profile2.png"
              alt="Welcome"
              className="mx-auto mb-1 w-[24%] md:w-[8%] h-auto"
            />
          </div>
          <div className="mt-12 xl:mt-20">
            <p className="xl:text-xl md:text-sm text-xs">
              <span className="font-semibold"></span>{" "}
              {profile.role
                ? profile.role.charAt(0).toUpperCase() + profile.role.slice(1)
                : "Loading..."}
            </p>
            <h2 className="text-sm md:text-xl xl:text-2xl font-bold font-serif text-black mb-2">
              {loading ? (
                // <Spinner />
                <div>Loading...</div>
              ) : (
                <div>
                  {profile.first_name
                    ? `${profile.first_name} ${profile.last_name}`
                    : "No Data Available"}
                </div>
              )}
            </h2>
          </div>
          {/* Toggle Buttons */}
          <div className="flex md:space-x-8 space-x-2 items-center justify-center mt-3">
            <button
              className={`py-2 md:px-4 px-2 text-xs md:text-sm  xl:text-xl font-bold font-serif rounded-t  ${
                activeSection === "postedJobs"
                  ? "bg-orange-700 text-white"
                  : "bg-gray-300 text-black"
              }`}
              onClick={() => setActiveSection("postedJobs")}
            >
              JOBS POSTED
            </button>

            <button
              className={`py-2 px-4 text-xs md:text-sm  xl:text-xl font-bold font-serif rounded-t  ${
                activeSection === "applicants"
                  ? "bg-orange-700 text-white"
                  : "bg-gray-300 text-black"
              }`}
              onClick={() => setActiveSection("applicants")}
            >
              APPLICANTS
            </button>
            <button
              className={`py-2 md:px-4 px-2 text-xs md:text-sm xl:text-xl font-bold font-serif rounded-t ${
                activeSection === "my_drivers"
                  ? "bg-orange-700 text-white"
                  : "bg-gray-300 text-black"
              }`}
              onClick={() => setActiveSection("my_drivers")}
            >
              MY DRIVERS
            </button>
            <button
              className={`py-2 md:px-4 px-2 text-xs md:text-sm xl:text-xl font-bold font-serif rounded-t ${
                activeSection === "profile"
                  ? "bg-orange-700 text-white"
                  : "bg-gray-300 text-black"
              }`}
              onClick={() => setActiveSection("profile")}
            >
              MY PROFILE
            </button>
          </div>
        </div>

        {/* Conditional Rendering of Sections */}
        {activeSection === "profile" && (
          <ProfileSection profile={profile} getWorkAreaName={getWorkAreaName} />
        )}

        {activeSection === "postedJobs" && (
          <PostedJobs
            jobData={jobData}
            loading={loading}
            getWorkAreaName={getWorkAreaName}
            getVehicleMakeName={getVehicleMakeName}
            getVehicleModelName={getVehicleModelName}
            closingJobId={closingJobId}
            handleCloseJob={handleCloseJob}
            handleCloseJobClick={handleCloseJobClick}
            showConfirmDialog={showConfirmDialog}
            setShowConfirmDialog={setShowConfirmDialog}
            showPopup={setShowPopup}
          />
        )}

        {activeSection === "my_drivers" && (
          <MyDrivers
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            partnerId={profile.partner_details.id}
          />
        )}

        {activeSection === "applicants" && (
          <Applicants
            jobApplicants={jobApplicants}
            loading={loading}
            getWorkAreaName={getWorkAreaName}
            getVehicleTypeName={getVehicleTypeName}
            AcceptingApplicationId={acceptingApplicationId}
            removingApplicationId={removingApplicationId}
            handleRemove={handleRemove}
            handleAccept={handleAccept}
          />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default PartnerDashboard;
