import { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../services/config";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const navigate = useNavigate();

    const loginAction = async (data, setErrorMessage) => {

        try {
            const response = await axios.post(`${BASE_URL}api/token/`, data);
            if (response.status === 200 && response.data.access && response.data.refresh) {

        // Store tokens and navigate based on the role
        setToken(response.data.access);
        localStorage.setItem("token", response.data.access);
        localStorage.setItem("refresh_token", response.data.refresh);

        if (data.role === "partner") {
          navigate("/partnerdashboard");
        } else if (data.role === "driver") {
          navigate("/driverdashboard");
        } else {
          navigate("/");
        }
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        if (err.response.data && err.response.data.non_field_errors) {
          setErrorMessage(err.response.data.non_field_errors.join(" "));
        } else {
          setErrorMessage("Invalid login credentials or unexpected error.");
        }
      } else {
        console.error("Login error:", err);
        setErrorMessage(
          "An error occurred during login. Please try again later."
        );
      }
    }
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
    navigate("/");
  };

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
